import React from 'react';
import getEnvironement from '../../../../environnement';
import useFetch from '../../../blogs/useFetch';
import './dernierArticles.css';

function Article({ icon, title, id }) {
  return (
    <div className='article'>
      <img className='rounded-[25px] object-cover' src={icon} alt='article' />
      <h3 className='text-2xl mt-4 md:text-2xl font-bold text-[#0A093D] mb-3'>{title}</h3>
      <p>
        <a href={`#/blogs/${id}`}>
          EN SAVOIR PLUS
        </a>
      </p>
    </div>
  );
}

export default function DernierArticles() {
  const BLOG_API_URL = `${getEnvironement().BLOG_API_URL}?populate=*&pagination[pageSize]=3&sort[0]=createdAt:desc`;

  const { loading, error, data } = useFetch(BLOG_API_URL);

  console.log('Fetched data:', data); // Debugging API response

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="loader border-t-4 border-blue-500 rounded-full w-12 h-12 animate-spin"></div>
      </div>
    );
  }

  if (error) {
    return <p>Error fetching articles: {error.message}</p>;
  }

  // Access articles from data.data
  const articles = Array.isArray(data?.data) ? data.data : [];

  if (articles.length === 0) {
    return <p>No articles available.</p>;
  }

  return (
    <section className='dernierArticlesContainer'>
      <div className='dernierArticles'>
        <div className="dernierArticlesText text-center">
          <p className='title t1 text-lg text-gray-600'>Actualités et Articles</p>
          <h2 className='title t2 text-4xl font-bold text-[#656565] mb-4'>
            Blog de Legalstation : Votre Source d&apos;Inspiration et de Connaissances
          </h2>
          <p className='title t3 text-gray-600 mb-12'>
            Explorez des articles, des conseils pratiques et des actualités pour vous accompagner dans votre parcours entrepreneurial et vous aider à naviguer dans le monde des affaires au Maroc.
          </p>
        </div>

        <div className='articleContainer'>
          {articles.map((article) => (
            <Article
              key={article.id}
              icon={`${getEnvironement().BLOG_URL}${article.coverImage?.url}`}
              title={article.blogTitle}
              id={article.id}
            />
          ))}
        </div>
      </div>
    </section>
  );
}
