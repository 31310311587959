import React from 'react';

import sarlUpload from '../../../../assets/images/sarlupload.png';
import Steps from './form/steps';
import Header from '../../../../components/header/header';
import Footer from '../../../../components/footer/footer';

export default function Sarl() {
  const customTitle = (
    <>
      Créez votre <span className='text-primary'>société</span>.<br></br> sans effort et rapidement !
    </>
  ); 
  return (
    <div className="bg-white
  }">
      {/* <SarlHeader /> */}
      <Header />
      <Steps type='maSociete' title={customTitle} imageSrc={sarlUpload} />
      
      <div className=" bg-link py-16 px-10 md:px-[120px] ">
        <h3 className='text-4xl  text-white font-bold'>Vous souhaitez démarrer <br></br> votre projet avec nous?</h3>
      </div>
      <Footer />
    </div>
  );
}
