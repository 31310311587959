import React, { useState } from 'react';
import CustomTextInput from '../../../../../../components/inputs/customTextInput/customTextInput';
import { Button, Alert } from '@mui/material';

export default function QDureeSociete({
  formData = {},
  setFormData,
  handleNextStep,
  handlePreviousStep,
}) {
  const [errors, setErrors] = useState({});

  const { dureeSociete = 99 } = formData;

  const validateInputs = () => {
    const newErrors = {};
    if (!dureeSociete || parseInt(dureeSociete, 10) <= 0) {
      newErrors.dureeSociete = 'La durée de la société doit être supérieure à 0.';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  // Update the formData
  const handleInputChange = (value) => {
    setFormData({
      ...formData,
      dureeSociete: value,
    });
  };

  // Handle the Continue button click
  const handleContinue = () => {
    if (validateInputs()) {
      // Pass the updated form data to handleNextStep
      handleNextStep({ dureeSociete });
    }
  };

  return (
    <div className="mb-10">
      {/* Input for Durée de la société */}
      <div className="bg-[#F6F7F9] rounded-lg pl-4 py-4 pr-2 mb-4">
        <CustomTextInput
          min={0}
          type="number"
          value={dureeSociete}
          setValue={handleInputChange}
          placeholder="Durée de la société en année"
          error={errors.dureeSociete}
        />
        <Alert severity="info" className="mt-2 rounded-[10px]">
          Par défaut, la durée de la société est définie à <strong>99 ans</strong>. Vous pouvez la modifier si nécessaire.
        </Alert>
      </div>

      {/* Navigation Buttons */}
      <div className="flex justify-center gap-5 mt-5">
        <Button
          color="info"
          variant="outlined"
          onClick={handlePreviousStep}
        >
          {'< Retour'}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleContinue}
        >
          Continuer
        </Button>
      </div>
    </div>
  );
}
