function range(nombre) {
  const result = [];
  for (let i = 1; i < parseInt(nombre) + 1; i++) {
    result.push(i);
  }
  return result;
}

function supprimerDerniersItems(liste, nombre) {
  return liste.slice(0, nombre);
}

function extraireNoms(liste) {
  return liste.map(item => {
    if (item.data.type === 'Personne physique') {
      return { label: `${item.data.nom} ${item.data.prenom}`, value: `${item.data.nom} ${item.data.prenom}` };
    } else if (item.data.type === 'Personne morale') {
      return { label: item.data.denomination, value: item.data.denomination };
    } else {
      return '';
    }
  });
}

export { range, supprimerDerniersItems, extraireNoms };