import React from 'react';
import './services.css';
import servicesBg from '../../../../assets/images/bg-services.svg';

export default function Services() {
  const services = [
    {
      title: 'Création d\'entreprise au Maroc',
      description: 'Simplifiez la création de votre entreprise au Maroc avec notre service dédié. Nous vous accompagnons à chaque étape, de l\'immatriculation au choix du statut juridique, pour lancer votre activité en toute sérénité.',
    },
    {
      title: 'Comptabilité en ligne',
      description: 'Bénéficiez d\'une solution de comptabilité en ligne moderne et efficace. Suivez vos finances en temps réel, automatisez vos tâches comptables et restez conforme aux obligations légales grâce à notre plateforme sécurisée.',
    },
    {
      title: 'Conseil en gestion d\'entreprise',
      description: 'Boostez la performance de votre entreprise grâce à nos services de conseil en gestion. Nous offrons des stratégies personnalisées pour optimiser vos opérations, maîtriser vos coûts et atteindre vos objectifs.',
    },
    {
      title: 'Conseil et documents juridiques',
      description: 'Obtenez des conseils juridiques fiables et des documents professionnels adaptés à vos besoins. Nous vous aidons à respecter les lois en vigueur tout en protégeant vos intérêts.',
    },
    {
      title: 'Offre exceptionnelle',
      description: 'Profitez d\'offres exclusives sur nos services pour démarrer ou développer votre entreprise. Une opportunité unique pour bénéficier d\'avantages compétitifs à un prix avantageux.',
    },
  
   
  ];

  return (
    <section className="servicesContainer bg-gray-100 py-16">
      <div
        className="services mx-auto max-w-7xl px-4"
        style={{
          backgroundImage: `url(${servicesBg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className="servicesText text-center py-8">
          <p className="title t1 text-lg text-gray-600">Expertise à votre service.</p>
          <h1 className="text-4xl font-bold text-gray-800">Nos Services</h1>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 mt-8">
          {services.map((service, index) => (
            <div
              key={index}
              className="flex flex-col p-6 gap-4 bg-white rounded-lg shadow-md"
              style={{
                minWidth: '250px',
                height: 'auto',
              }}
            >
              <div className="circle-gradient"></div>
              <h3 className="service-title">{service.title}</h3>
              <p className="service-description">{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
