import React, { useState } from 'react';
// import './customTextInput.css';
import { TextField } from '@mui/material';

export default function CustomTextInput({
  value, setValue, placeholder, type = 'text', min, error
}) {
  const [typeInput, setTypeInput] = useState(type);

  function getInput(type) {
    switch (type) {
    case 'number':
      return (
        <div className="w-full m-4">
          <TextField
            type={type}
            value={value}
            min={min}
            onChange={(e) => setValue(e.target.value)}
            placeholder={placeholder}
            className="textinput pl-10 pr-3 py-2  w-full outline-none border-grayv4 border-2 rounded-[10px] focus:border-primary bg-white"
            label={placeholder}
          />
          {error && <p className="text-red">{error}</p>}
        </div>
      );
    case 'date':
      return (
        <div className="w-full mt-4">
          <TextField
            type={typeInput}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder={placeholder}
            className="pl-10 pr-3 py-2 w-full outline-none border-grayv4 border-2 rounded-[10px] focus:border-primary bg-white"
            onFocus={() => setTypeInput('date')}
            onBlur={() => setTypeInput('text')}
          />
          {error && <p className="text-red">{error}</p>}
        </div>
      );
    default:
      return (
        <div className="w-full mt-4">
          <TextField
            type={type}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder={placeholder}
            className="pl-10 pr-3 py-2 w-full outline-none border-grayv4 border-2 rounded-[10px] focus:border-primary bg-white"
            label={placeholder}
          />
          {error && <p className="text-red">{error}</p>}
        </div>
      );
    }
  }

  return (
    <div className="label-img relative flex items-center w-[300px] w-full mb-2 pr-2">
      {getInput(type)}
    </div>
  );
}
