import React from 'react';
import { Button, CircularProgress } from '@mui/material';

export default function LoadingButton({ 
  children, // Button text or elements
  loading = false, // Loading state
  disabled = false, // Whether the button is disabled
  ...props // Other button props
}) {
  return (
    <Button
      {...props}
      disabled={disabled || loading} // Disable when loading
      startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null} // Spinner icon
    >
      {loading ? 'Chargement...' : children} {/* Show loading text */}
    </Button>
  );
}
