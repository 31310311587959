import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ColoredRectangle from './components/coloredRectangle';
import Box from '../../assets/icons/box.png';
import BoxRemove from '../../assets/icons/box-remove.png';
import BoxTime from '../../assets/icons/box-time.png';
import BoxTick from '../../assets/icons/box-tick.png';
import EmptyWalletTick from '../../assets/icons/empty-wallet-tick.png';
import EmptyWalletTime from '../../assets/icons/empty-wallet-time.png';
import EmptyWalletRemove from '../../assets/icons/empty-wallet-remove.png';
import Chart from './components/chart';
import getEnvironement from '../../environnement';
import DashHeader from './components/dashHeader';
import { useSelector } from 'react-redux';
import { userSelector } from '../../store/userSlice';

function Statistics() {
  const user = useSelector(userSelector);
  const [stats, setStats] = useState({
    enregistres: 0,
    rejetes: 0,
    en_cours: 0,
    validees: 0,
    payes: 0,
    non_complets: 0,
  });

  useEffect(() => {
    axios
      .post(`${getEnvironement().API_URL}/admin/user-statistics`, {
        headers: {
          'Content-Type': 'application/json',
        },
        user_id: user.id
      })
      .then((response) => setStats(response.data))
      .catch((error) => console.error('Error fetching statistics:', error));
  }, [user]);

  const rectanglesData = [
    {
      id: 1,
      rectangleColor: 'bg-gradient',
      hoverColor: 'bg-blue-300',
      icon: Box,
      number: stats.enregistres,
      text: 'Nombre de produits enregistrés',
    },
    {
      id: 2,
      rectangleColor: 'bg-staticred',
      hoverColor: 'bg-green-300',
      icon: BoxRemove,
      number: stats.rejetes,
      text: 'Nombre de produits rejetées',
    },
    {
      id: 3,
      rectangleColor: 'bg-staticyellow',
      hoverColor: 'bg-yellow-300',
      icon: BoxTime,
      number: stats.en_cours,
      text: 'Nombre de produits en cours de traitement',
    },
    {
      id: 4,
      rectangleColor: 'bg-staticpink',
      hoverColor: 'bg-red-300',
      icon: BoxTick,
      number: stats.validees,
      text: 'Nombre de produits validée',
    },
    {
      id: 5,
      rectangleColor: 'bg-staticpink',
      hoverColor: 'bg-purple-300',
      icon: EmptyWalletTick,
      number: stats.payes,
      text: 'Nombre de produits totalement payés',
    },
    {
      id: 6,
      rectangleColor: 'bg-staticgreen',
      hoverColor: 'bg-indigo-300',
      icon: EmptyWalletTime,
      number: stats.non_complets,
      text: 'Nombre de produits avec un paiement non complet',
    },
    {
      id: 7,
      rectangleColor: 'bg-staticpurple',
      hoverColor: 'bg-pink-300',
      icon: EmptyWalletRemove,
      number: stats.non_complets,
      text: 'Nombre de produits non payés',
    },
    {
      id: 8,
      rectangleColor: 'bg-primary',
      hoverColor: 'bg-gray-300',
      icon: EmptyWalletTick,
      number: 8,
      text: 'Montant total payée',
    },
  ];

  return (
    <>
      <DashHeader
        picture={`${getEnvironement().BACKEND_URL}/storage/app/public/${user?.profile_picture}`}
        name={user?.nom}
      />
      <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6">
        {rectanglesData.map((rectangle) => (
          <ColoredRectangle
            key={rectangle.id}
            rectangleColor={rectangle.rectangleColor}
            hoverColor={rectangle.hoverColor}
            icon={rectangle.icon}
            number={rectangle.number}
            text={rectangle.text}
            onClick={() => console.log(`Clicked on ${rectangle.text}`)}
          />
        ))}
      </div>
      <div className="mt-10 flex flex-col lg:flex-row gap-4">
        <div className="sm:w-full md:w-1/2 space-y-4">
          <p className="font-bold text-balance">Nombre de produits validés</p>
          <Chart />
        </div>
        <div className="sm:w-full md:w-1/2 space-y-4">
          <p className="font-bold text-balance">Paiement</p>
          <Chart />
        </div>
      </div>
    </>
  );
}

export default Statistics;
