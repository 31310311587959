import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Pack from './Pack';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import getEnvironement from '../../../../../../environnement';

function QPacks({ formData = {}, setFormData, handleNextStep, handlePreviousStep }) {
  const { pack } = formData;
  const [allPacks, setAllPacks] = useState([]);
  const [isEmptyPacks, setIsEmptyPacks] = useState(false);
  const [circularProgress, setCircularProgress] = useState(false);

  const getPacks = async () => {
    setCircularProgress(true);
    const url = `${getEnvironement().API_URL}/admin/packs-by-category?category_name=Création d'entreprise`;

    try {
      const response = await axios.get(url);
      setAllPacks(response.data);

      if (response.data.length === 0) {
        setIsEmptyPacks(true);
      } else {
        setIsEmptyPacks(false);
      }
    } catch (error) {
      console.error('Error fetching packs:', error);
    } finally {
      setCircularProgress(false);
    }
  };

  useEffect(() => {
    getPacks();

    // Set the default pack if it’s not already set
    if (!pack) {
      setFormData((prev) => ({
        ...prev,
        pack: null,
      }));
    }
  }, []); // Only runs once when the component mounts

  const handleClickPack = (selectedPack) => {
    setFormData((prev) => ({
      ...prev,
      pack: selectedPack.id, // Set the pack_id in formData
    }));

    handleNextStep({ pack: selectedPack.id });
  };

  console.log(formData);
  
  return (
    <div className="p-4">
      <div className="bg-[#F6F7F9] rounded-lg px-6 py-4 mb-6">
        <p className="text-base font-medium text-neutral mb-4">Choisir votre pack:</p>
        {circularProgress ? (
          <div className="flex justify-center items-center h-20">
            <CircularProgress />
          </div>
        ) : isEmptyPacks ? (
          <p>Aucun pack disponible pour cette catégorie.</p>
        ) : (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 justify-center items-start">
            {allPacks.map((pack) => (
              <Pack
                key={pack.id}
                name={pack.type}
                items={pack.items}
                price={pack.price}
                selectedPack={formData.pack}
                onClick={() => handleClickPack(pack)}
              />
            ))}
          </div>
        )}
      </div>
      <div className="flex flex-col md:flex-row justify-center gap-5 mt-5">
        <Button color="info" variant="outlined" onClick={handlePreviousStep}>
          {'< Retour'}
        </Button>
      </div>
    </div>
  );
}

export default QPacks;
