import React from 'react';

function Pack({ name, items, price, selectedPack, onClick }) {
  // Helper function to format price as currency
  const formatPrice = (value) => {
    return new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'MAD',
    })
      .format(value)
      .replace('MAD', 'DH'); // Replace currency symbol with DH
  };

  return (
    <div
      className={`border p-4 cursor-pointer bg-white hover:bg-primary rounded-lg hover:text-white ${selectedPack === name && 'border-2 border-primary'}`}
      onClick={() => onClick(name)}
    >
      <p className="text-xl text-center font-bold py-2">{name}</p>
      <hr className="mb-4" />
      <ul className="list-disc pl-4">
        {items.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
      <hr className="mt-4" />
      <p className="text-xl font-bold py-2">prix: {formatPrice(price)}</p>
    </div>
  );
}

export default Pack;
