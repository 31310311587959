import React, { useEffect, useState } from 'react';
import CustomTextInput from '../../../../../../components/inputs/customTextInput/customTextInput';
import { Typography ,Alert } from '@mui/material';

export default function ObjetSocial({ id, setObjetsSocial, objetSocialData = {}, error = {} }) {
  const [objetSocial, setObjetSocial] = useState(objetSocialData.objetSocial || '');
  const [charCount, setCharCount] = useState(objetSocial.length);
  const maxChars = 250;

  useEffect(() => {
    setObjetsSocial({ id, objetSocial });
  }, [objetSocial, id, setObjetsSocial]);

  const handleInputChange = (value) => {
    if (value.length <= maxChars) {
      setObjetSocial(value);
      setCharCount(value.length);
    }
  };

  return (
    <div className="flex flex-col gap-2">
      <CustomTextInput
        value={objetSocial}
        setValue={handleInputChange}
        placeholder={`Objet social ${id}`}
        error={error.objetSocial}
      />
      <Typography variant="caption" color={charCount === maxChars ? 'error' : 'textSecondary'}>
        {charCount}/{maxChars} caractères
      </Typography>
      <Alert severity="info" className="mt-2 rounded-[10px]">
        Astuce : Fournissez une description concise et claire (par exemple, &quot;Importation et vente de matériel informatique&quot;).
      </Alert>
    </div>
  );
}
