import React, { useState } from 'react';
import HeaderContainer from '../../../components/headerContainer/headerContainer';
import './inscription.css';
import {  TextField } from '@mui/material';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import getEnvironement from '../../../environnement';
import Loading_Button from '../../../components/inputs/loading_Button';

export default function Inscription() {
  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [loading, setLoading] = useState(false); // Loading state added

  const navigate = useNavigate();

  const handleInscription = () => {
    setLoading(true); // Start loading
    const url = `${getEnvironement().API_URL}/auth/register`;
    const formData = new FormData();
    formData.append('nom', nom);
    formData.append('prenom', prenom);
    formData.append('email', email);
    formData.append('phone', phone);
    formData.append('password', password);
    formData.append('confirmPassword', confirmPassword);
    formData.append('birthDate', birthDate);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    axios.post(url, formData, config)
      .then(() => {
        setLoading(false); // Stop loading on success
        navigate('/login', { state: { data: 'succes-inscription' } });
      })
      .catch((err) => {
        setLoading(false); // Stop loading on error
        console.log(err);
      });
  };

  return (
    <div className='inscription-container pt-16'>
      <HeaderContainer />
      <div className='inscription'>
        <div className='inscription-form'>
          <p className='title'>Inscription:</p>
          <div className='name-container'>
            <label htmlFor='nom' className='nom'>
              Nom:
              <TextField
                id="nom"
                variant="outlined"
                type='text'
                value={nom}
                onChange={(e) => setNom(e.target.value)}
              />
            </label>
            <label htmlFor='prenom' className='prenom'>
              Prenom:
              <TextField
                id="prenom"
                variant="outlined"
                type='text'
                value={prenom}
                onChange={(e) => setPrenom(e.target.value)}
              />
            </label>
          </div>
          <label htmlFor='email' className='email'>
            Email:
            <TextField
              id="email"
              variant="outlined"
              type='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>
          <label htmlFor='tel' className='tel'>
            Numéro de téléphone:
            <TextField
              id="tel"
              variant="outlined"
              type='tel'
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </label>
          <label htmlFor='birthDate' className='birthDate'>
            Date de naissance:
            <TextField
              id="birthDate"
              variant="outlined"
              type='date'
              value={birthDate}
              onChange={(e) => setBirthDate(e.target.value)}
            />
          </label>
          <label htmlFor='password' className='password'>
            Mot de passe:
            <TextField
              id="password"
              variant="outlined"
              type='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </label>
          <label htmlFor='confirmPassword' className='confirmPassword'>
            Confirmation de mot de passe:
            <TextField
              id="confirmPassword"
              variant="outlined"
              type='password'
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </label>

          <Loading_Button
            className='inscription-button'
            variant='contained'
            onClick={handleInscription}
            loading={loading

            }          >
            {'Inscription'}
          </Loading_Button>
          <div className='inscription-link-container'>
            <Link to={'/login'}> {'j\'ai déjà un compte'}</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
