import React, { useState } from 'react';
import CustomTextInput from '../../../../../../components/inputs/customTextInput/customTextInput';
import CustomSelect from '../../../../../../components/inputs/customSelect';
import { Button } from '@mui/material';

export default function QBeneficiaire({
  formData = {},
  setFormData,
  handleNextStep,
}) {
  const [errors, setErrors] = useState({});

  const {
    typeBeneficiaire = 'Personne physique',
    nomBeneficiaire = '',
    prenomBeneficiaire = '',
    dateBirthBeneficiaire = '',
    nationaliteBeneficiaire = '',
    cinBeneficiaire = '',
    denominationBeneficiaire = '',
    iceBeneficiaire = '',
    rcBeneficiaire = '',
    gsmBeneficiare = '',
    emailBeneficiaire = '',
    adresseBeneficiaire = '',
  } = formData;

  const updateField = (key, value) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
    setErrors((prev) => ({ ...prev, [key]: '' })); // Clear error on input change
  };

  const validateForm = () => {
    const newErrors = {};

    if (typeBeneficiaire === 'Personne physique') {
      if (!nomBeneficiaire) newErrors.nomBeneficiaire = 'Le nom est requis.';
      if (!prenomBeneficiaire) newErrors.prenomBeneficiaire = 'Le prénom est requis.';
      if (!nationaliteBeneficiaire) newErrors.nationaliteBeneficiaire = 'La nationalité est requise.';
      if (!cinBeneficiaire) newErrors.cinBeneficiaire = 'Le CIN est requis.';
      if (!dateBirthBeneficiaire) newErrors.dateBirthBeneficiaire = 'La date de naissance est requise.';
      if (!gsmBeneficiare && !/^\+?[0-9]{10,15}$/.test(gsmBeneficiare)) newErrors.gsmBeneficiare = 'Le GSM est requis.';
      if (!emailBeneficiaire || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailBeneficiaire)) {
        newErrors.emailBeneficiaire = 'L\'e-mail est invalide.';
      }
    } else {
      if (!iceBeneficiaire) newErrors.iceBeneficiaire = 'L\'ICE est requis.';
      if (!rcBeneficiaire) newErrors.rcBeneficiaire = 'Le RC est requis.';
      if (!denominationBeneficiaire) {
        newErrors.denominationBeneficiaire = 'La dénomination commerciale est requise.';
      }
    }

    if (!adresseBeneficiaire) newErrors.adresseBeneficiaire = 'L\'adresse est requise.';

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const handleContinue = () => {
    if (validateForm()) {
      let dataToSubmit = {};
      if (typeBeneficiaire === 'Personne physique') {
        dataToSubmit = {
          typeBeneficiaire,
          nomBeneficiaire,
          prenomBeneficiaire,
          nationaliteBeneficiaire,
          cinBeneficiaire,
          dateBirthBeneficiaire,
          gsmBeneficiare,
          emailBeneficiaire,
          adresseBeneficiaire,
        };
      } else {
        dataToSubmit = {
          typeBeneficiaire,
          iceBeneficiaire,
          rcBeneficiaire,
          denominationBeneficiaire,
          adresseBeneficiaire,
        };
      }

      handleNextStep(dataToSubmit);
    }
  };

  return (
    <div className="w-full border-solid border-1 border-red-400 mb-10">
      <div className="bg-[#F6F7F9] rounded-lg pl-4 py-4 pr-2 mb-4">
        <p className="text-base font-medium text-neutral mb-2">Bénéficiaire :</p>
        <CustomSelect
          value={typeBeneficiaire}
          setValue={(value) => updateField('typeBeneficiaire', value)}
          defaultValue="Personne physique"
          items={[
            { label: 'Personne physique', value: 'Personne physique' },
            { label: 'Personne morale', value: 'Personne morale' },
          ]}
        />
      </div>

      {typeBeneficiaire === 'Personne physique' ? (
        <>
          <div className="flex flex-col md:flex-row bg-[#F6F7F9] rounded-lg pl-4 py-4 pr-2 mb-4">
            <CustomTextInput
              value={nomBeneficiaire}
              setValue={(value) => updateField('nomBeneficiaire', value)}
              placeholder="Nom"
              error={errors.nomBeneficiaire}
            />
            <CustomTextInput
              value={prenomBeneficiaire}
              setValue={(value) => updateField('prenomBeneficiaire', value)}
              placeholder="Prénom"
              error={errors.prenomBeneficiaire}
            />
            <CustomTextInput
              value={nationaliteBeneficiaire}
              setValue={(value) => updateField('nationaliteBeneficiaire', value)}
              placeholder="Nationalité"
              error={errors.nationaliteBeneficiaire}
            />
          </div>
          <div className="flex flex-col md:flex-row bg-[#F6F7F9] rounded-lg pl-4 py-4 pr-2 mb-4">
            <CustomTextInput
              value={cinBeneficiaire}
              setValue={(value) => updateField('cinBeneficiaire', value)}
              placeholder="CIN"
              error={errors.cinBeneficiaire}
            />
            <CustomTextInput
              type="date"
              value={dateBirthBeneficiaire}
              setValue={(value) => updateField('dateBirthBeneficiaire', value)}
              placeholder="Date de naissance "
              error={errors.dateBirthBeneficiaire}
            />
            <CustomTextInput
              value={gsmBeneficiare}
              setValue={(value) => updateField('gsmBeneficiare', value)}
              placeholder="GSM"
              error={errors.gsmBeneficiare}
            />
            <CustomTextInput
              value={emailBeneficiaire}
              setValue={(value) => updateField('emailBeneficiaire', value)}
              placeholder="E-mail"
              error={errors.emailBeneficiaire}
            />
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col md:flex-row bg-[#F6F7F9] rounded-lg pl-4 py-4 pr-2 mb-4">
            <CustomTextInput
              value={iceBeneficiaire}
              setValue={(value) => updateField('iceBeneficiaire', value)}
              placeholder="Identifiant commun de l'entreprise (ICE)"
              error={errors.iceBeneficiaire}
            />
            <CustomTextInput
              value={rcBeneficiaire}
              setValue={(value) => updateField('rcBeneficiaire', value)}
              placeholder="Numéro de registre de commerce (RC)"
              error={errors.rcBeneficiaire}
            />
            <CustomTextInput
              value={denominationBeneficiaire}
              setValue={(value) => updateField('denominationBeneficiaire', value)}
              placeholder="Dénomination commerciale"
              error={errors.denominationBeneficiaire}
            />
          </div>
        </>
      )}

      <div className="flex flex-col md:flex-row bg-[#F6F7F9] rounded-lg pl-4 py-4 pr-2 mb-4">
        <CustomTextInput
          value={adresseBeneficiaire}
          setValue={(value) => updateField('adresseBeneficiaire', value)}
          placeholder="Adresse de correspondance du Bénéficiaire"
          error={errors.adresseBeneficiaire}
        />
      </div>

      <div className="flex justify-center gap-5 mt-5">
        <Button
          color="primary"
          variant="contained"
          onClick={handleContinue}
        >
          Continuer
        </Button>
      </div>
    </div>
  );
}
