import React from 'react';

export default function Article({
  image,
  rubrique,
  date,
  title,
  description,
  actorName,
  actorProfession,
  isFirstArticle = false,
  onClick,
}) {
  const colors = ['bg-[#3498db]', 'bg-[#2ecc71]', 'bg-[#e74c3c]', 'bg-[#9b59b6]'];
  const avatarColor = colors[Math.floor(Math.random() * colors.length)];

  return (
    <div
      className={`font-inter flex ${isFirstArticle ? 'flex-row gap-8' : 'flex-col'} cursor-pointer transition-transform transform hover:scale-105`}
      onClick={onClick}
    >
      <img
        className={`rounded-[25px] ${isFirstArticle ? 'w-[48.5%] max-h-[350px] object-cover' : 'w-full h-[300px] object-cover'}`}
        src={image}
        alt="article"
      />
      <div className={`${isFirstArticle ? 'w-[48.5%]' : 'mt-4'}`}>
        <div className="flex items-center mb-4">
          <p className="text-xl text-primary font-medium">{rubrique}</p>
          <div className="mx-2 w-5 border border-[#0A093D]" />
          <p className="text-xl text-[#656464] font-medium">{date}</p>
        </div>
        <h1 className="text-2xl md:text-3xl font-bold text-[#0A093D] mb-3">{title}</h1>
        <p className="text-base font-normal text-[#656464] line-clamp-3">{description}</p>
        <div className="flex items-center gap-5 mt-5">
          <div
            className={`w-12 h-12 text-white rounded-full ${avatarColor} flex justify-center items-center text-lg font-semibold`}
          >
            {actorName.charAt(0).toUpperCase()}
          </div>
          <div>
            <p className="font-medium text-[#0A093D] text-lg">{actorName}</p>
            <p className="text-sm font-normal text-[#656464]">{actorProfession}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
