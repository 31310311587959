import React from 'react';
import DashboardIcon from '../../assets/icons/DashboardIcon.png';
import ProfileIcon from '../../assets/icons/ProfileIcon.png';
import RecordsIcon from '../../assets/icons/RecordsIcon.png';
import ContactIcon from '../../assets/icons/ContactIcon.png';
import logo from '../../assets/images/LOGO-LS@2x.png';
import BottomBar from './bottombar';
import { useDispatch } from 'react-redux';
import { setUser } from '../../store/userSlice';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';



function Sidebar({ isCollapsed, handleToggle, handleTabClick, activeTab }) {
  const dispatch = useDispatch();
  const buttonClasses = 'flex items-center p-4 focus:outline-none transition-colors duration-200';

  return (
    <>
      <div className={`hidden md:flex flex-col justify-between ${isCollapsed ? 'w-24' : 'w-64'} h-screen text-black transition-width duration-300 bg-white overflow-hidden shadow-md`}>
        <button style={{ paddingTop: '5px', paddingBottom: '20px' }} onClick={handleToggle} className=" focus:outline-none">
          {isCollapsed
            ? (
              <>
                <hr className="border-t border-gray-300" />
                <div className='flex  justify-center items-center shadow-md w-full gap-4 p-4 bg-white shadow-md rounded-xl '>
                  <div className=' rounded-xl'>
                    <MenuOutlinedIcon fontSize='small' />
                  </div>
                </div>
              </>
            )
            : (
              <>
                <hr className="border-t border-gray-300" />
                <div className='flex  justify-center items-center shadow-md w-full gap-4 p-4 bg-white shadow-md rounded-xl '>
                  <span><img src={logo} alt="" /></span>
                  <CloseOutlinedIcon fontSize='small' />
                </div>
              </>

            )
          }
        </button>

        <div className='flex flex-col gap-48'>
          <nav className="flex flex-col mt-4 space-y-2 w-full">
            {[
              { name: 'dashboard', icon: DashboardIcon, label: 'Dashboard' },
              { name: 'profile', icon: ProfileIcon, label: 'Profile' },
              { name: 'records', icon: RecordsIcon, label: 'Mes Enregistrements' },
              { name: 'contact', icon: ContactIcon, label: 'Contact' },
            ].map(tab => (
              <button
                key={tab.name}
                onClick={() => handleTabClick(tab.name)}
                className={buttonClasses}
              >
                <div className={`flex items-center ${isCollapsed ? 'justify-start w-full' : 'justify-start w-full'}`}>
                  <div className="w-5 flex-shrink-0 flex justify-center">
                    {activeTab === tab.name && (
                      <div className="w-1 h-1 rounded-full bg-primary"></div>
                    )}
                  </div>
                  <img
                    src={tab.icon}
                    alt={tab.label}
                    className="h-4 w-4"
                  />
                  {!isCollapsed && <span className="ml-2">{tab.label}</span>}
                </div>
              </button>
            ))}
          </nav>
          <div>
            <hr className="border-t border-gray-300" />
            <div className="flex flex-col justify-center rounded-full shadow-md mb-10 items-center w-full">

              <button
                onClick={() => {
                  const confirmLogout = window.confirm('Êtes-vous sûr de vouloir se déconnecter ?');
                  if (confirmLogout) {
                    dispatch(setUser(null));
                  }
                }}
                className='flex items-center  space-x-2 p-2  transition-colors'
                style={{
                  color: '#FF0000', // Red color for the logout text and icon
                }}
                onMouseEnter={(e) => (e.currentTarget.style.color = '#CC0000')} // Darker red on hover
                onMouseLeave={(e) => (e.currentTarget.style.color = '#FF0000')} // Original red on mouse leave
              >
                <LogoutOutlinedIcon fontSize='small' />
                {!isCollapsed && <span>Déconnexion</span>}
              </button>
            </div>
          </div>
        </div>
      </div>
      <BottomBar activeTab={activeTab} handleTabClick={handleTabClick} />
    </>
  );
}

export default Sidebar;
