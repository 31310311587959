import React, { useEffect, useState } from 'react';
import CustomTextInput from '../../../../../../components/inputs/customTextInput/customTextInput';
import CustomSelect from '../../../../../../components/inputs/customSelect';
import { Button } from '@mui/material';
import { range, supprimerDerniersItems } from '../../utils';


function Gerent({ id, soussignes, setGerents, gerentData = {}, error = {} }) {
  const [details, setDetails] = useState({
    soussigne: gerentData.soussigne || null,
    autre: gerentData.autre || false,
    nom: gerentData.nom || '',
    prenom: gerentData.prenom || '',
    nationalite: gerentData.nationalite || '',
    date_birth: gerentData.date_birth || '',
    address: gerentData.address || '',
    cin: gerentData.cin || '',
  });

  useEffect(() => {
    setGerents({ id, ...details });
  }, [details, setGerents]);

  // Transform `soussignes` into a list of { label, value } objects
  const extraireNoms = (liste) => {
    return liste.map((item) => {
      if (item.data.type === 'Personne physique') {
        const label = `${item.data.nom} ${item.data.prenom}`;
        return { label, value: item };
      } else if (item.data.type === 'Personne morale') {
        const label = item.data.denomination;
        return { label, value: item };
      }
      return null;
    });
  };

  const options = [...extraireNoms(soussignes), { label: 'Autre', value: 'autre' }];

  return (
    <div className="flex flex-col gap-4">
      <CustomSelect
        value={details.soussigne}
        setValue={(value) =>
          setDetails((prev) => ({
            ...prev,
            soussigne: value,
            autre: value === 'autre',
          }))
        }
        items={options}
        error={error.soussigne}
      />

      {details.autre && (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <CustomTextInput
            type="text"
            value={details.nom}
            setValue={(value) => setDetails((prev) => ({ ...prev, nom: value }))}
            placeholder="Nom"
            error={error.nom}
          />
          <CustomTextInput
            type="text"
            value={details.prenom}
            setValue={(value) => setDetails((prev) => ({ ...prev, prenom: value }))}
            placeholder="Prénom"
            error={error.prenom}
          />
          <CustomTextInput
            type="text"
            value={details.nationalite}
            setValue={(value) => setDetails((prev) => ({ ...prev, nationalite: value }))}
            placeholder="Nationalité"
            error={error.nationalite}
          />
          <CustomTextInput
            type="date"
            value={details.date_birth}
            setValue={(value) => setDetails((prev) => ({ ...prev, date_birth: value }))}
            placeholder="Date de naissance"
            error={error.date_birth}
          />
          <CustomTextInput
            type="text"
            value={details.address}
            setValue={(value) => setDetails((prev) => ({ ...prev, address: value }))}
            placeholder="Adresse"
            error={error.address}
          />
          <CustomTextInput
            type="text"
            value={details.cin}
            setValue={(value) => setDetails((prev) => ({ ...prev, cin: value }))}
            placeholder="CIN"
            error={error.cin}
          />
        </div>
      )}
    </div>
  );
}



export default function QGerents({ formData = {}, setFormData, handleNextStep, handlePreviousStep }) {
  const { gerents = [], nombreGerents = formData.soussignes.length } = formData;
  const [errors, setErrors] = useState({});
  const [pouvoirs, setPouvoirs] = useState('separement');

  useEffect(() => {
    setFormData((prev) => {
      const updatedGerents = range(nombreGerents).map((id) => {
        const existingData = prev.gerents?.find((item) => item.id === id);
        return (
          existingData || {
            id,
            soussigne: '',
            autre: false,
            nom: '',
            prenom: '',
            nationalite: '',
            date_birth: '',
            address: '',
            cin: '',
          }
        );
      });

      return {
        ...prev,
        gerents: supprimerDerniersItems(updatedGerents, nombreGerents),
      };
    });

    setErrors({});
  }, [nombreGerents]);

  const modifyGerentsData = (idGerent, gerentData) => {
    const updatedGerents = [...gerents];
    updatedGerents[idGerent - 1] = gerentData;
    setFormData((prev) => ({
      ...prev,
      gerents: updatedGerents,
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!nombreGerents || isNaN(nombreGerents) || nombreGerents < 1) {
      newErrors.nombreGerents =
        'Le nombre de gérants est requis et doit être au moins 1.';
    }

    gerents.forEach((gerent, index) => {
      if (!gerent?.soussigne) {
        newErrors[`soussigne_${index}`] = `Gérant ${index + 1}: le soussigné est requis.`;
      }
      if (gerent.autre) {
        if (!gerent.nom) newErrors[`nom_${index}`] = `Gérant ${index + 1}: le nom est requis.`;
        if (!gerent.prenom) newErrors[`prenom_${index}`] = `Gérant ${index + 1}: le prénom est requis.`;
        if (!gerent.nationalite) newErrors[`nationalite_${index}`] = `Gérant ${index + 1}: la nationalité est requise.`;
        if (!gerent.date_birth) newErrors[`date_birth_${index}`] = `Gérant ${index + 1}: la date de naissance est requise.`;
        if (!gerent.address) newErrors[`address_${index}`] = `Gérant ${index + 1}: l'adresse est requise.`;
        if (!gerent.cin) newErrors[`cin_${index}`] = `Gérant ${index + 1}: le CIN est requis.`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    if (validateForm()) {
      handleNextStep({ gerents, nombreGerents, pouvoirs });
    }
  };

  return (
    <div className="mb-10">
      <div className="bg-[#F6F7F9] rounded-lg pl-4 py-4 pr-2 mb-4">
        <p className="text-base font-medium text-neutral mb-2">
          Ajouter / Supprimer un gérant :
        </p>
        <CustomTextInput
          type="number"
          min={1}
          value={nombreGerents}
          setValue={(value) =>
            setFormData((prev) => ({
              ...prev,
              nombreGerents: parseInt(value, 10) || 1,
            }))
          }
          error={errors.nombreGerents}
        />
      </div>
      <div className="bg-[#F6F7F9] rounded-lg pl-4 py-4 pr-2 mb-4">
        <p className="text-base font-medium text-neutral mb-2">Gérants :</p>
        {gerents.map((gerent) => (
          <Gerent
            key={gerent.id}
            id={gerent.id}
            gerentData={gerent}
            soussignes={formData.soussignes}
            setGerents={(data) => modifyGerentsData(gerent.id, data)}
            error={{
              soussigne: errors[`soussigne_${gerent.id - 1}`],
              nom: errors[`nom_${gerent.id - 1}`],
              prenom: errors[`prenom_${gerent.id - 1}`],
              nationalite: errors[`nationalite_${gerent.id - 1}`],
              date_birth: errors[`date_birth_${gerent.id - 1}`],
              address: errors[`address_${gerent.id - 1}`],
              cin: errors[`cin_${gerent.id - 1}`],
            }}
          />
        ))}
      </div>
      {nombreGerents > 1 && (
        <div className="bg-[#F6F7F9] rounded-lg pl-4 py-4 pr-2 mb-4">
          <p className="font-medium text-neutral mb-2">Clause juridique :</p>
          <div className="flex items-center gap-4">
            <label className="flex items-center gap-2">
              <input
                type="radio"
                value="separement"
                checked={pouvoirs === 'separement'}
                onChange={(e) => setPouvoirs(e.target.value)}
              />
              <span>Signature séparée des co-gérants.</span>
            </label>
            <label className="flex items-center gap-2">
              <input
                type="radio"
                value="ensemble"
                checked={pouvoirs === 'ensemble'}
                onChange={(e) => setPouvoirs(e.target.value)}
              />
              <span>Signature conjointe des co-gérants.</span>
            </label>
          </div>
        </div>
      )}
      <div className="flex justify-center gap-5 mt-5">
        <Button color="info" variant="outlined" onClick={handlePreviousStep}>
          {'< Retour'}
        </Button>
        <Button color="primary" variant="contained" onClick={handleNext}>
          Continuer
        </Button>
      </div>
    </div>
  );
}
