import React from 'react';
import './customersFeedback.css';

export default function CustomersFeedback() {
  const feedbacks = [
    {
      image: 'https://via.placeholder.com/64/1a73e8',
      name: 'Amine L',
      job: 'Gérant de PME',
      feedback:
        'Le processus de création d\'entreprise avec LegalStation.ma a été un vrai gain de temps pour moi. J\'avais besoin d\'un accompagnement simple et rapide pour lancer ma société, et tout a été fait en ligne. L\'équipe m\'a guidé de A à Z, et leurs conseils juridiques m\'ont permis d\'éviter des erreurs coûteuses. Je suis très satisfait.'
    },
    {
      image: 'https://via.placeholder.com/64/34a853',
      name: 'Khadija R',
      job: 'Indépendante',
      feedback:
        'J\'étais un peu sceptique à l\'idée de gérer la comptabilité de ma nouvelle entreprise en ligne, mais LegalStation.ma m\'a prouvé que c\'était non seulement possible, mais incroyablement efficace ! Leur équipe est très professionnelle et toujours prête à répondre à mes questions. Je peux désormais me concentrer sur le développement de mon activité.'
    },
    {
      image: 'https://via.placeholder.com/64/fbbc05',
      name: 'Youssef M',
      job: 'Entrepreneur',
      feedback:
        'LegalStation.ma m\'a offert un service clé en main pour toutes les formalités administratives de ma société. Le fait que tout soit digitalisé m\'a énormément facilité la tâche. Leur service est rapide, abordable, et surtout très professionnel. C\'est un partenaire de confiance pour tout entrepreneur au Maroc.'
    },
    {
      image: 'https://via.placeholder.com/64/0a9396',
      name: 'Sarah B.',
      job: 'Consultante en gestion',
      feedback:
        'LegalStation.ma m\'a aidée à simplifier toutes les démarches juridiques pour créer mon entreprise. Leur expertise et leur disponibilité m\'ont vraiment impressionnée. Je les recommande sans hésitation à toute personne souhaitant démarrer une activité au Maroc.'
    },
    {
      image: 'https://via.placeholder.com/64/ee9b00',
      name: 'Omar T.',
      job: 'Développeur Web Freelance',
      feedback:
        'Avec LegalStation.ma, j\'ai pu me concentrer sur mon activité principale sans me soucier des formalités administratives. Leur plateforme est intuitive et l\'équipe est très réactive. Un vrai atout pour les freelances !'
    },
    {
      image: 'https://via.placeholder.com/64/005f73',
      name: 'Imane H.',
      job: 'Designer Graphique',
      feedback:
        'LegalStation.ma a transformé ma vision des démarches administratives. Tout est clair, rapide et efficace. C\'est une solution parfaite pour les créateurs d\'entreprise qui cherchent un accompagnement fiable.'
    }
  ];

  return (
    <section className='customersFeedbackContainer py-16'>
      <div className='customersFeedback mx-auto max-w-7xl px-4'>
        <div className="customersFeedbackText text-center ">
          <p className='title t1 text-lg text-gray-600'>Témoignages de réussite.</p>
          <h2 className='title t2 w-[687px] text-4xl font-bold text-[#656565] mb-4'>
            Avis clients - Pourquoi ils nous font confiance
          </h2>
          <p className='title t3 text-gray-600 mb-12'>
            Témoignages de clients satisfaits qui soulignent l&apos;efficacité de notre plateforme digitale pour la création et la gestion d’entreprise.
          </p>
        </div>


        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8'>
          {feedbacks.map((feedback, index) => (
            <div
              key={index}
              className='feedbackCard  p-6  shadow-md flex flex-col items-start text-left'
            >
              <div className='flex items-center gap-4 mb-4'>
                <img
                  src={feedback.image}
                  alt={feedback.name}
                  className='w-16 h-16 rounded-full'
                />
                <div>
                  <h3 className='text-lg font-semibold '>
                    {feedback.name}
                  </h3>
                  <p className='text-sm text-[#656565]'>{feedback.job}</p>
                </div>
              </div>
              <p className='text-gray-600'>{feedback.feedback}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}
