import React, { useState } from 'react';
import Header from '../../components/header/header';
import SideBarPack from './sideBarPack';
import PackItem from './packItem';
import axios from 'axios';
import getEnvironement from '../../environnement';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import entreprisePackImage1 from '../../assets/images/entreprisePack1.png';
import entreprisePackImage2 from '../../assets/images/entreprisePack2.png';
import entreprisePackImage3 from '../../assets/images/entreprisePack3.png';

const usePacksData = (categoryName) => {
  const [packs, setPacks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  React.useEffect(() => {
    const fetchPacks = async () => {
      setLoading(true);
      try {
        const url = `${getEnvironement().API_URL}/admin/packs-by-category?category_name=${encodeURIComponent(categoryName)}`;
        const response = await axios.get(url);
        setPacks(response.data);
      } catch (err) {
        setError(err);
        console.error('Error fetching packs:', err);
      } finally {
        setLoading(false);
      }
    };

    fetchPacks();
  }, [categoryName]);

  return { packs, loading, error };
};

export default function Packs() {
  const [activeTab, setActiveTab] = useState('Création d\'entreprise');
  const { packs, loading, error } = usePacksData(activeTab);

  const images = [entreprisePackImage1, entreprisePackImage2, entreprisePackImage3];

  return (
    <div className='lg:flex lg:justify-center'>
      <div className='min-h-screen w-full flex flex-col 2xl:w-[1536px]'>
        <Header active='Packs' />
        <div className='flex flex-1'>
          <SideBarPack activeTab={activeTab} setActiveTab={setActiveTab} />
          <div className='p-10 w-full pt-32'>
            <div className='flex flex-col justify-center  gap-5 mt-10 lg:flex-row'>
              {loading && (
                <Box display="flex"  justifyContent="center" alignItems="center" height="100%" width="100%">
                  <CircularProgress />
                </Box>
              )}

              {error && (
                <Box display="flex" justifyContent="center" alignItems="center" height="100%" width="100%">
                  <Typography color="error" variant="h6">
                  Erreur lors du chargement des packs: {error?.message || 'Unknown error'}
                  </Typography>
                </Box>
              )}

              {!loading && !error && !packs.length && (
                <Box display="flex" justifyContent="center" alignItems="center" height="100%" width="100%">
                  <Typography variant="h6">Aucun pack disponible pour cette catégorie.</Typography>
                </Box>
              )}

              {!loading && !error && packs.map((pack, index) => (
                <PackItem
                  key={pack.id}
                  offer={pack.offer || ''}
                  price={pack.price}
                  image={images[index % images.length]} // Cycle through images
                  items={pack.items}
                  subPrice={pack.sub_price || ''}
                  onClick={() => console.log(`Selected pack: ${pack.id}`)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
