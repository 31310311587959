import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { format } from 'date-fns';
import documentIcon from '../../../assets/icons/documentIcon.png';
import productIdIcon from '../../../assets/icons/productIdIcon.png';
import getEnvironement from '../../../environnement';

function DocumentsTable({
  header,
  data,
  isProductPayed,
  actions
}) {
  return (
    <>
      <table className='table-auto w-full'>
        <thead>
          <tr className='border-solid border-b border-[#EBEBEB]'>
            {header.map((item, index) => {
              return (
                <th
                  key={item}
                  className={`font-poppins font-medium text-sm/[16px] opacity-50 py-5 ${index === 0 && 'text-start w-24'}`}
                >
                  {item}
                </th>
              );
            })}
          </tr>
        </thead>
        {isProductPayed && (
          <tbody>
            {data.map((document, index) => {
              return (
                <tr key={index} className='border-solid border-b border-[#EBEBEB]'>
                  <td>
                    <div className='min-w-32 md:min-w-none flex py-2 items-center font-poppins font-normal text-sm/[16px]'>
                      <img src={productIdIcon} />
                      <p className='ml-3 max-w-14 break-words'>{document.id}</p>
                    </div>
                  </td>
                  <td className='font-poppins font-normal text-sm/[16px] text-center opacity-50'>{document.name}</td>
                  <td className='font-poppins font-normal text-sm/[16px] text-center'>{format(new Date(document.created_at), 'yyyy-MM-dd HH:mm')}</td>
                  <td className='py-5 text-center'>
                    {/* Document Download Action */}
                    <Tooltip title='Télécharger document'>
                      <a href={`${getEnvironement().BACKEND_URL}/storage/pdfs/${document.file}`} target='_blank' rel="noopener noreferrer">
                        <IconButton>
                          <img src={documentIcon} alt='document' style={{ width: '24px', height: '24px' }} />
                        </IconButton>
                      </a>
                    </Tooltip>
                  </td>

                  <td className='py-5 text-center'>
                    {/* File Upload Action (if product is paid) */}
                    {isProductPayed && actions && (
                      <div className='flex justify-center items-center space-x-4'>
                        {actions(document)}
                      </div>
                    )}
                  </td>


                </tr>
              );
            })}
          </tbody>
        )}
      </table>
      {data.length === 0 && isProductPayed && (
        <div className='rounded border border-dashed border-1 opacity-50 p-5 m-5 text-center font-poppins font-normal text-sm/[16px]'>Aucun document</div>
      )}
      {!isProductPayed && (
        <div className='rounded border border-dashed border-1 opacity-50 p-5 m-5 text-center font-poppins font-normal text-sm/[16px]'>Merci d&apos;acheter le pack pour recevoir les documents</div>
      )}
    </>
  );
}

export default DocumentsTable;