import React, { useState, useEffect } from 'react';
import CustomTextInput from '../../../../../../components/inputs/customTextInput/customTextInput';
import CustomSelect from '../../../../../../components/inputs/customSelect';

export default function Personne({
  title,
  setPersonne,
  errors = {},
  data = {},
}) {
  const [typePersonne, setTypePersonne] = useState(data.type || 'Personne physique');
  const [nomPersonne, setNomPersonne] = useState(data.nom || '');
  const [prenomPersonne, setPrenomPersonne] = useState(data.prenom || '');
  const [nationalitePersonne, setNationalitePersonne] = useState(data.nationalite || '');
  const [villeResidencePersonne, setVilleResidencePersonne] = useState(data.villeResidence || '');
  const [cinPersonne, setCinPersonne] = useState(data.cin || '');
  const [dateBirthPersonne, setDateBirthPersonne] = useState(data.dateBirth || '');
  const [denominationPersonne, setDenominationPersonne] = useState(data.denomination || '');
  const [gsmPersonne, setGsmPersonne] = useState(data.gsm || '');
  const [capitalSocialPersonne, setCapitalSocialPersonne] = useState(data.capitalSocial || '');
  const [formeJuridiquePersonne, setFormeJuridiquePersonne] = useState(
    data.formeJuridique || 'Société à responsabilité limitée'
  );
  const [emailPersonne, setEmailPersonne] = useState(data.email || '');
  const [adressePersonne, setAdressePersonne] = useState(data.adresse || '');
  const [icePersonne, setIcePersonne] = useState(data.ice || '');
  const [rcPersonne, setRcPersonne] = useState(data.rc || '');


  useEffect(() => {
    if (typePersonne === 'Personne physique') {
      setPersonne({
        type: 'Personne physique',
        nom: nomPersonne,
        prenom: prenomPersonne,
        nationalite: nationalitePersonne,
        cin: cinPersonne,
        dateBirth: dateBirthPersonne,
        email: emailPersonne,
        gsm: gsmPersonne,
        villeResidence: villeResidencePersonne,
        adresse: adressePersonne,
      });
    } else {
      setPersonne({
        type: 'Personne morale',
        denomination: denominationPersonne,
        capitalSocial: capitalSocialPersonne,
        formeJuridique: formeJuridiquePersonne,
        ice: icePersonne,
        rc: rcPersonne,
        villeSciege: villeResidencePersonne,
        adresseSiege: adressePersonne,
        nomRepresentant: nomPersonne,
        prenomRepresentant: prenomPersonne,
        emailRepresantant: emailPersonne,
        gsmRepresentant: gsmPersonne,
        cinRepresentant: cinPersonne,
      });
    }
  }, [data, typePersonne, nomPersonne, prenomPersonne, nationalitePersonne, villeResidencePersonne, cinPersonne, denominationPersonne, icePersonne, rcPersonne, gsmPersonne, emailPersonne, adressePersonne, capitalSocialPersonne, formeJuridiquePersonne]);


  return (
    <div>
      <p className="text-base font-medium text-neutral mb-2">{title}:</p>
      <CustomSelect
        value={typePersonne}
        setValue={setTypePersonne}
        defaultValue="Personne physique"
        items={[
          { label: 'Personne physique', value: 'Personne physique' },
          { label: 'Personne morale', value: 'Personne morale' },
        ]}
      />

      {typePersonne === 'Personne physique' ? (
        <>
          <div className="flex flex-col md:flex-row">
            <CustomTextInput
              value={nomPersonne}
              setValue={setNomPersonne}
              placeholder="Nom"
              error={errors.nom}
            />
            <CustomTextInput
              value={prenomPersonne}
              setValue={setPrenomPersonne}
              placeholder="Prénom"
              error={errors.prenom}
            />
            <CustomTextInput
              value={nationalitePersonne}
              setValue={setNationalitePersonne}
              placeholder="Nationalité"
              error={errors.nationalite}
            />
          </div>
          <div className="flex flex-col md:flex-row">
            <CustomTextInput
              value={cinPersonne}
              setValue={setCinPersonne}
              placeholder="CIN"
              error={errors.cin}
            />
            <CustomTextInput
              value={gsmPersonne}
              setValue={setGsmPersonne}
              placeholder="GSM"
              error={errors.gsm}
            />
            <CustomTextInput
              value={emailPersonne}
              setValue={setEmailPersonne}
              placeholder="E-mail"
              error={errors.email}
            />
          </div>
          <div className="flex flex-col md:flex-row">
            <CustomTextInput
              type="date"
              value={dateBirthPersonne}
              setValue={setDateBirthPersonne}
              placeholder="Date de naissance "
              error={errors.dateBirth}
            />
            <CustomTextInput
              value={villeResidencePersonne}
              setValue={setVilleResidencePersonne}
              placeholder="Ville de résidence"
              error={errors.villeResidence}
            />
            <CustomTextInput
              value={adressePersonne}
              setValue={setAdressePersonne}
              placeholder="Adresse de résidence"
              error={errors.adresse}
            />
          </div>
        </>
      ) : (
        <>
          <CustomTextInput
            value={denominationPersonne}
            setValue={setDenominationPersonne}
            placeholder="Dénomination commerciale"
            error={errors.denomination}
          />
          <div className="flex flex-col md:flex-row">
            <CustomSelect
              value={formeJuridiquePersonne}
              setValue={setFormeJuridiquePersonne}
              items={[
                { label: 'Société à responsabilité limitée', value: 'Société à responsabilité limitée' },
                { label: 'Société à responsabilité limitée à associé unique', value: 'Société à responsabilité limitée à associé unique' },
                { label: 'Société anonyme', value: 'Société anonyme' },
                { label: 'Société en nom collectif', value: 'Société en nom collectif' },
                { label: 'Société en commandite par actions', value: 'Société en commandite par actions' },
                { label: 'Groupement d\'intérêt économique', value: 'Groupement d\'intérêt économique' },
                { label: 'Société civile immobilière', value: 'Société civile immobilière' },
                { label: 'Société en participation', value: 'Société en participation' },
                { label: 'Société par actions simplifiée', value: 'Société par actions simplifiée' },
                { label: 'Succursale d’une société étrangère', value: 'Succursale d’une société étrangère' }
              ]}
            />
            <CustomTextInput
              type="number"
              min={0}
              value={capitalSocialPersonne}
              setValue={setCapitalSocialPersonne}
              placeholder="Montant du capital social"
              error={errors.capitalSocial}
            />
          </div>
          <div className="flex flex-col md:flex-row ">
            <CustomTextInput
              value={icePersonne}
              setValue={setIcePersonne}
              placeholder="Identifiant commun de l'entreprise (ICE)"
              error={errors.ice}
            />
            <CustomTextInput
              value={rcPersonne}
              setValue={setRcPersonne}
              placeholder="Numéro de registre de commerce (RC)"
              error={errors.rc}
            />
          </div>
          <div className="flex flex-col md:flex-row">
            <CustomTextInput
              value={villeResidencePersonne}
              setValue={setVilleResidencePersonne}
              placeholder="Ville du siège social"
              error={errors.villeSciege}
            />
            <CustomTextInput
              value={adressePersonne}
              setValue={setAdressePersonne}
              placeholder="Adresse du siège social"
              error={errors.adresseSiege}
            />
          </div>
          <div className="flex flex-col md:flex-row">
            <CustomTextInput
              value={nomPersonne}
              setValue={setNomPersonne}
              placeholder="Nom du représentant"
              error={errors.nomRepresentant}
            />
            <CustomTextInput
              value={prenomPersonne}
              setValue={setPrenomPersonne}
              placeholder="Prénom du représentant"
              error={errors.prenomRepresentant}
            />
            <CustomTextInput
              value={cinPersonne}
              setValue={setCinPersonne}
              placeholder="CIN du représentant"
              error={errors.cinRepresentant}
            />
          </div>
          <div className="flex flex-col md:flex-row">
            <CustomTextInput
              value={gsmPersonne}
              setValue={setGsmPersonne}
              placeholder="GSM du représentant"
              error={errors.gsmRepresentant}
            />
            <CustomTextInput
              value={emailPersonne}
              setValue={setEmailPersonne}
              placeholder="E-mail du représentant"
              error={errors.emailRepresantant}
            />
          </div>
        </>
      )}
    </div>
  );
}