import React, { useEffect, useState } from 'react';
import CustomSelect from '../../../../../../components/inputs/customSelect';
import CustomTextInput from '../../../../../../components/inputs/customTextInput/customTextInput';
import { extraireNoms } from '../../utils';

export default function PartSocial({ id, soussignes, setPartsSocial, partSocialData = {}, error = {} }) {
  const [soussigne, setSoussigne] = useState(partSocialData.soussigne || '');
  const [partSocial, setPartSocial] = useState(partSocialData.partSocial || '');

  useEffect(() => {
    setPartsSocial({ id, soussigne, partSocial: parseFloat(partSocial) || '' });
  }, [soussigne, partSocial ,setPartsSocial]);



  return (
    <div className="flex flex-col md:flex-row gap-4">
      <CustomSelect
        value={soussigne}
        setValue={setSoussigne}
        items={extraireNoms(soussignes)}
        error={error.soussigne}
      />
      <CustomTextInput
        type="number"
        value={partSocial}
        setValue={setPartSocial}
        placeholder="Part Social"
        error={error.partSocial}
      />
    </div>
  );
}
