import React, { useState } from 'react';
import CustomTextInput from '../../../../../../components/inputs/customTextInput/customTextInput';
import CustomSelect from '../../../../../../components/inputs/customSelect';
import { Button } from '@mui/material';

export default function QEntreprise({
  formData = {},
  setFormData,
  handleNextStep,
  handlePreviousStep,
}) {
  const {
    denominations = [{ name: '', sigle: '' }, { name: '', sigle: '' }, { name: '', sigle: '' }],
    formeJuridique = '',
    ville = '',
    activiteCommercial = '',
  } = formData;

  const [errors, setErrors] = useState({});

  const updateField = (key, value) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  const handleDenominationChange = (index, field, value) => {
    const updatedDenominations = [...denominations];
    updatedDenominations[index] = {
      ...updatedDenominations[index],
      [field]: value,
    };
    updateField('denominations', updatedDenominations);
  };

  const validateForm = () => {
    const validationErrors = {};

    // Validate each denomination for unique name
    const nameSet = new Set();
    denominations.forEach((denomination, index) => {
      if (!denomination.name) {
        validationErrors[`denominationName${index}`] = `Dénomination ${index + 1} est requise.`;
      } else if (nameSet.has(denomination.name)) {
        validationErrors[`denominationName${index}`] = `Dénomination ${denomination.name} est dupliquée.`;
      } else {
        nameSet.add(denomination.name);
      }
    });

    // Validate other fields
    if (!formeJuridique) validationErrors.formeJuridique = 'Forme juridique est requise.';
    if (!ville) validationErrors.ville = 'Ville est requise.';
    if (!activiteCommercial)
      validationErrors.activiteCommercial = 'Activité commercial est requise.';

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };


  const handleContinue = () => {
    if (validateForm()) {
      const dataToSubmit = {
        denominations,
        formeJuridique,
        ville,
        activiteCommercial,
      };
      handleNextStep(dataToSubmit);
    }
  };

  return (
    <div className="w-full border-solid border-1 border-red-400 mb-10">
      {/* Section: Dénominations proposées */}
      <div className="bg-[#F6F7F9] rounded-lg pl-4 py-4 pr-2 mb-4">
        <p className="text-base font-medium text-neutral mb-2">
          Dénominations proposées par le Bénéficiaire:
        </p>
        {denominations.map((denomination, index) => (
          <div className="flex flex-col md:flex-row mt-4" key={index}>
            <div className="w-full md:w-1/2 pr-2 mb-2 md:mb-0">
              <CustomTextInput
                value={denomination.name || ''}
                setValue={(value) => handleDenominationChange(index, 'name', value)}
                placeholder={`Dénomination ${index + 1}`}
                error={errors[`denominationName${index}`]}
              />
            </div>
            <div className="w-full md:w-1/2">
              <CustomTextInput
                value={denomination.sigle || ''}
                setValue={(value) => handleDenominationChange(index, 'sigle', value)}
                placeholder={`Sigle ${index + 1}`}
                error={errors[`denominationSigle${index}`]}
              />
            </div>
          </div>
        ))}
      </div>

      {/* Section: Forme Juridique */}
      <div className="bg-[#F6F7F9] rounded-lg pl-4 py-4 pr-2 mb-4">
        <p className="text-base font-medium text-neutral mb-2">Forme Juridique:</p>
        <CustomSelect
          value={formeJuridique}
          setValue={(value) => updateField('formeJuridique', value)}
          items={[
            { label: 'Société à responsabilité limitée', value: 'Société à responsabilité limitée' },
            { label: 'Société à responsabilité limitée à associé unique', value: 'Société à responsabilité limitée à associé unique' },
            { label: 'Société anonyme', value: 'Société anonyme' },
            { label: 'Société en nom collectif', value: 'Société en nom collectif' },
            { label: 'Société en commandite par actions', value: 'Société en commandite par actions' },
            { label: 'Groupement d\'intérêt économique', value: 'Groupement d\'intérêt économique' },
            { label: 'Société civile immobilière', value: 'Société civile immobilière' },
            { label: 'Société en participation', value: 'Société en participation' },
            { label: 'Société par actions simplifiée', value: 'Société par actions simplifiée' },
            { label: 'Succursale d’une société étrangère', value: 'Succursale d’une société étrangère' }
          ]}
          error={errors.formeJuridique}
        />
      </div>

      {/* Section: Ville */}
      <div className="bg-[#F6F7F9] rounded-lg pl-4 py-4 pr-2 mb-4">
        <CustomTextInput
          value={ville}
          setValue={(value) => updateField('ville', value)}
          placeholder="Ville"
          error={errors.ville}
        />
      </div>

      {/* Section: Activité Commercial */}
      <div className="bg-[#F6F7F9] rounded-lg pl-4 py-4 pr-2 mb-4">
        <p className="text-base font-medium text-neutral mb-2">Activité commercial:</p>
        <CustomSelect
          value={activiteCommercial}
          setValue={(value) => updateField('activiteCommercial', value)}
          items={[
            { value: 'A', label: 'AGRICULTURE, SYLVICULTURE ET PÊCHE' },
            { value: 'B', label: 'INDUSTRIES EXTRACTIVES' },
            { value: 'C', label: 'INDUSTRIE MANUFACTURIÈRE' },
            { value: 'D', label: 'PRODUCTION ET DISTRIBUTION D\'ÉLECTRICITÉ, DE GAZ, DE VAPEUR ET D\'AIR CONDITIONNÉ' },
            { value: 'E', label: 'PRODUCTION ET DISTRIBUTION D\'EAU ; ASSAINISSEMENT, GESTION DES DÉCHETS ET DÉPOLLUTION' },
            { value: 'F', label: 'CONSTRUCTION' },
            { value: 'G', label: 'COMMERCE ; RÉPARATION D\'AUTOMOBILES ET DE MOTOCYCLES' },
            { value: 'H', label: 'TRANSPORTS ET ENTREPOSAGE' },
            { value: 'I', label: 'HÉBERGEMENT ET RESTAURATION' },
            { value: 'J', label: 'INFORMATION ET COMMUNICATION' },
            { value: 'K', label: 'ACTIVITÉS FINANCIÈRES ET D\'ASSURANCE' },
            { value: 'L', label: 'ACTIVITÉS IMMOBILIÈRES' },
            { value: 'M', label: 'ACTIVITÉS SPÉCIALISÉES, SCIENTIFIQUES ET TECHNIQUES' },
            { value: 'N', label: 'ACTIVITÉS DE SERVICES ADMINISTRATIFS ET DE SOUTIEN' },
            { value: 'O', label: 'ADMINISTRATION PUBLIQUE' },
            { value: 'P', label: 'ENSEIGNEMENT' },
            { value: 'Q', label: 'SANTÉ HUMAINE ET ACTION SOCIALE' },
            { value: 'R', label: 'ARTS, SPECTACLES ET ACTIVITÉS RÉCRÉATIVES' },
            { value: 'S', label: 'AUTRES ACTIVITÉS DE SERVICES' },
            { value: 'T', label: 'ACTIVITÉS DES MÉNAGES EN TANT QU\'EMPLOYEURS ; ACTIVITÉS INDIFFÉRENCIÉES DES MÉNAGES EN TANT QUE PRODUCTEURS DE BIENS ET SERVICES POUR USAGE PROPRE' },
            { value: 'U', label: 'ACTIVITÉS EXTRA-TERRITORIALES' },
          ]}
          error={errors.activiteCommercial}
        />
      </div>

      {/* Navigation Buttons */}
      <div className="flex justify-center gap-5 mt-5">
        <Button color="info" variant="outlined" onClick={handlePreviousStep}>
          {'< Retour'}
        </Button>
        <Button color="primary" variant="contained" onClick={handleContinue}>
          Continuer
        </Button>
      </div>
    </div>
  );
}
