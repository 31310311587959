import React, { useEffect, useState } from 'react';
import CustomTextInput from '../../../../../../components/inputs/customTextInput/customTextInput';
import ObjetSocial from './ObjetSocial';
import { supprimerDerniersItems, range } from '../../utils';
import { Button } from '@mui/material';

function QObjetSocial({
  formData = { objetsSocial: [], nombreObjetSocial: 1 },
  setFormData,
  handleNextStep,
  handlePreviousStep,
}) {
  const { objetsSocial = [], nombreObjetSocial = 1 } = formData;
  const [errors, setErrors] = useState({});

  useEffect(() => {
    console.log('Before update:', formData); // Debugging: Track formData state before update

    setFormData((prev) => {
      const objetsSocial = prev.objetsSocial || []; // Ensure objetsSocial is an array
      const updatedObjetsSocial = range(nombreObjetSocial).map((id) => {
        const existingData = objetsSocial.find((item) => item.id === id);
        return existingData || { id, objetSocial: '' };
      });

      console.log('Updated objetsSocial:', updatedObjetsSocial); // Debugging: Track updated objetsSocial

      return {
        ...prev,
        objetsSocial: supprimerDerniersItems(updatedObjetsSocial, nombreObjetSocial),
      };
    });

    setErrors({}); // Reset errors when nombreObjetSocial changes
  }, [nombreObjetSocial, setFormData]);

  const modifyObjetsSocialData = (idObjetSocial, objetSocialData) => {
    const updatedObjetsSocial = [...objetsSocial];
    updatedObjetsSocial[idObjetSocial - 1] = objetSocialData;
    setFormData((prev) => ({
      ...prev,
      objetsSocial: updatedObjetsSocial,
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!nombreObjetSocial || isNaN(nombreObjetSocial) || nombreObjetSocial < 1) {
      newErrors.nombreObjetSocial = 'Le nombre d\'objets sociaux est requis et doit être au moins 1.';
    }

    objetsSocial.forEach((objet, index) => {
      if (!objet?.objetSocial || objet.objetSocial.trim() === '') {
        newErrors[`objetSocial_${index}`] = `Objet social ${index + 1}: est requis.`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    if (validateForm()) {
      handleNextStep({ objetsSocial, nombreObjetSocial });
    }
  };

  return (
    <div className="mb-10">
      <div className="bg-[#F6F7F9] rounded-lg pl-4 py-4 pr-2 mb-4">
        <p className="text-base font-medium text-neutral mb-2">Ajouter / Supprimer un objet social:</p>
        <CustomTextInput
          type="number"
          min={1}
          value={nombreObjetSocial}
          setValue={(value) =>
            setFormData((prev) => ({
              ...prev,
              nombreObjetSocial: parseInt(value, 10) || 1,
            }))
          }
          error={errors.nombreObjetSocial}
        />
      </div>

      <div className="bg-[#F6F7F9] rounded-lg pl-4 py-4 pr-2 mb-4">
        <p className="text-base font-medium text-neutral mb-2">Objets sociaux:</p>
        {objetsSocial.map((objetSocial) => (
          <div key={objetSocial.id} className="mb-4">
            <ObjetSocial
              id={objetSocial.id}
              objetSocialData={objetSocial}
              setObjetsSocial={(data) => modifyObjetsSocialData(objetSocial.id, data)}
              error={{
                objetSocial: errors[`objetSocial_${objetSocial.id - 1}`],
              }}
            />
          </div>
        ))}
      </div>

      <div className="flex justify-center gap-5 mt-5">
        <Button color="info" variant="outlined" onClick={handlePreviousStep}>
          {'< Retour'}
        </Button>
        <Button color="primary" variant="contained" onClick={handleNext}>
          Continuer
        </Button>
      </div>
    </div>
  );
}

export default QObjetSocial;
