import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useFetch from './useFetch';
import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import getEnvironement from '../../environnement';
import Article from './article';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';

const rubriques = [
  'Tous les articles',
  'Finance',
  'Juridique',
  'Entrepreneuriat',
  'Ressources humaines',
  'Marketing',
];

export default function Blogs() {
  const { loading, error, data } = useFetch(`${getEnvironement().BLOG_API_URL}?populate=*`);
  const [activeTab, setActiveTab] = useState('Tous les articles');
  const navigate = useNavigate();

  // Filters articles based on the active tab
  const filterArticles = () => {
    if (!data?.data) return [];
    return data.data.filter((article) => {
      const rubrique = article?.rubrique;
      return activeTab === 'Tous les articles' || rubrique === activeTab;
    });
  };

  // Renders articles dynamically
  const renderArticles = () => {
    const articles = filterArticles();
    if (articles.length === 0) {
      return (
        <div className="flex flex-col items-center justify-center my-[90px] space-y-4">

          <ArticleOutlinedIcon
            className='animate-bounce'
            style={{ fontSize: '80px', color: '#9CA3AF' }}
          />
          <p className="text-center text-lg text-gray-600 font-medium">
            Aucun article trouvé dans cette rubrique.
          </p>
          <button
            onClick={() => window.location.reload()}
            className="px-6 py-2 text-white bg-blue-600 hover:bg-blue-700 rounded-lg transition duration-300"
          >
            Actualiser
          </button>
        </div>
      );
    }

    const [featuredArticle, ...otherArticles] = articles;
    return (
      <div className="w-full  px-6 lg:px-[150px] my-[90px]">
        {/* Featured Article */}
        {featuredArticle && (
          <div className="mb-10">
            <Article
              key={featuredArticle.id}
              image={`${getEnvironement().BLOG_URL}${featuredArticle.coverImage?.url}`}
              rubrique={featuredArticle.rubrique}
              date={featuredArticle.date}
              title={featuredArticle.blogTitle}
              description={featuredArticle.blogDesc}
              actorName={featuredArticle.actorName}
              actorProfession={featuredArticle.actorProfession}
              isFirstArticle
              onClick={() => navigate(`${featuredArticle.id}`)}
            />
          </div>
        )}

        {/* Other Articles */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
          {otherArticles.map((blog) => (
            <Article
              key={blog.id}
              image={`${getEnvironement().BLOG_URL}${blog.coverImage?.url}`}
              rubrique={blog.rubrique}
              date={blog.date}
              title={blog.blogTitle}
              description={blog.blogDesc}
              actorName={blog.actorName}
              actorProfession={blog.actorProfession}
              onClick={() => navigate(`${blog.id}`)}
            />
          ))}
        </div>
      </div>
    );
  };

  // Renders the rubrique tabs for filtering articles
  const renderTabs = () => (
    <div className="sticky top-[50px] pt-[80px] bg-white z-10 shadow-sm">
      <div className="flex justify-center gap-6 px-4 py-4 overflow-x-auto border-b border-gray-300">
        {rubriques.map((rubrique) => (
          <button
            key={rubrique}
            className={`pb-2 text-base sm:text-lg font-medium whitespace-nowrap transition ${activeTab === rubrique
              ? 'text-blue-600 border-b-2 border-blue-600'
              : 'text-gray-600 hover:text-blue-600'
            }`}
            onClick={() => setActiveTab(rubrique)}
          >
            {rubrique}
          </button>
        ))}
      </div>
    </div>
  );

  // Renders the loader for a polished UX
  const renderLoader = () => (
    <div className="flex justify-center items-center h-64">
      <div className="loader border-t-4 border-blue-500 rounded-full w-12 h-12 animate-spin"></div>
    </div>
  );

  return (
    <div className="min-h-screen flex flex-col justify-between bg-gray-50">
      <Header />
      <main className="flex-grow">
        {renderTabs()}
        {loading ? (
          renderLoader()
        ) : error ? (
          <p className="text-center text-red-500 mt-10">Error: {error}</p>
        ) : (
          <>
            {renderArticles()}
          </>
        )}
      </main>
      <Footer />
    </div>
  );
}
