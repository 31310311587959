import QBeneficiaire from './components/steps/QBeneficiaire';
import QEntreprise from './components/steps/QEntreprise';
import QSoussignes from './components/steps/QSoussignes';
import QSiegeSocial from './components/steps/QSiegeSocial';
import QDureeSociete from './components/steps/QDureeSociete';
import QObjetSocial from './components/steps/QObjetSocial';
import QApports from './components/steps/QApports';
import QPartsSocial from './components/steps/QPartsSocial';
import QGerents from './components/steps/QGerent';
import QPacks from './components/steps/QPacks';
import QEmailTel from './components/steps/QEmailTel';


const questions = [
  { component: QBeneficiaire },
  { component: QEntreprise },
  { component: QSoussignes },
  { component: QSiegeSocial },
  { component: QDureeSociete },
  { component: QObjetSocial },
  { component: QApports },
  { component: QPartsSocial },
  { component: QGerents },
  { component: QPacks },
  { component: QEmailTel },
];

export default questions;
