import React, { useState, useRef } from 'react';
import './frequentlyAskedQuestions.css';
import { Button } from '@mui/material';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';

export default function FrequentlyAskedQuestions() {
  const [activeIndex, setActiveIndex] = useState(null);
  const [showAll, setShowAll] = useState(false);  // State to control showing all questions
  const faqSectionRef = useRef(null); // Ref for the FAQ section

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const scrollToBottom = () => {
    if (faqSectionRef.current) {
      faqSectionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end', inline: 'nearest',
      });
    }
  };

  const scrollToTop = () => {
    if (faqSectionRef.current) {
      faqSectionRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const faqs = [
    {
      question: 'Quels sont les types de sociétés que je peux créer au Maroc ?',
      answer: `
      Au Maroc, les formes juridiques les plus courantes sont :
            - SARL (Société à Responsabilité Limitée) : Idéal pour les petites et moyennes entreprises, avec un minimum de 1 ou 2 associés.
            - SA (Société Anonyme) : Convient pour les grandes entreprises, nécessite au moins 5 actionnaires.
            - SNC (Société en Nom Collectif) : Partenariat où les associés sont solidairement responsables.
            - Auto-entrepreneur : Statut simplifié pour les activités individuelles.
      
      Legalstation se charge de vous conseiller sur la forme juridique la plus adaptée à votre projet et d’assurer toutes les démarches administratives liées.
      `,
    },
    {
      question: 'Quels sont les documents nécessaires pour créer une entreprise ?',
      answer: `
      Les principaux documents requis incluent :
            - Copies des cartes d’identité nationale (CIN) des associés ou actionnaires.
            - Certificat négatif pour vérifier et réserver le nom de l’entreprise.
            - Projet des statuts de la société.
            - Attestation de blocage des fonds (pour SARL et SA).
            - Domiciliation de l’entreprise avec justificatif de l’adresse.
      
      Legalstation collecte, prépare et soumet ces documents pour vous, en s’assurant qu’ils respectent les exigences légales.
      `,
    },
    {
      question: 'Combien coûte la création d’une entreprise au Maroc ?',
      answer: `Le coût dépend de nombreux facteurs, mais pour offrir une création d’entreprise de qualité qui répond parfaitement à vos besoins business, Legalstation a conçu des packs adaptés et personnalisés. Ces packs incluent des prestations complètes pour garantir une création d’entreprise sans failles, conforme à la réglementation et optimisée pour vos objectifs.      - Étude et conseil sur mesure.
     1. Étude et conseil sur mesure :
              - Analyse approfondie de votre activité pour choisir la forme juridique la plus adaptée.
              - Conseils stratégiques sur la fiscalité et la domiciliation pour optimiser vos opérations dès le départ.

     2. Démarches administratives complètes :
              - Obtenir le certificat négatif pour protéger le nom de votre entreprise.
              - Rédaction et enregistrement des statuts par des experts juridiques pour éviter toute erreur.
              - Domiciliation de l’entreprise avec des adresses reconnues et conformes aux exigences légales.

     3. Gestion des obligations légales :
              - Publication dans le Bulletin Officiel et un journal d’annonces légales.
              - Immatriculation au registre de commerce.
              - Déclaration fiscale et inscription à la CNSS.

     4. Accompagnement personnalisé:
              - Assistance dédiée à chaque étape avec un gestionnaire expert.
              - Accès à une plateforme en ligne pour suivre l’avancement en temps réel.

      Avec Legalstation, vous obtenez une estimation transparente et optimisée pour éviter les frais imprévus.
      `,
    },
    {
      question: 'Quelles sont les étapes administratives pour créer une société ?',
      answer: `
      Les étapes incluent :

          1. Choisir la forme juridique et le nom de l’entreprise.
          2. Obtenir le certificat négatif.
          3. Préparer et enregistrer les statuts.
          4. Domicilier l’entreprise.
          5. Immatriculer au registre de commerce.
          6. Déclarer l’entreprise à la CNSS et aux impôts.
      
      Legalstation gère l’intégralité de ce processus pour vous.
      `,
    },
    {
      question: 'Où domicilier mon entreprise ?',
      answer: `
      La domiciliation peut se faire :

          - Dans un local commercial.
          - À domicile (selon certaines conditions).
          - Auprès d’une société de domiciliation spécialisée.
      
      Legalstation vous aide à trouver la solution la plus adaptée à votre activité.
      `,
    },
    {
      question: 'Combien de temps faut-il pour créer une entreprise ?',
      answer: `
      En général, il faut entre 7 et 15 jours ouvrables, selon la rapidité de la validation des documents par les administrations.
      Avec Legalstation, nous garantissons un traitement rapide grâce à nos experts.
      `,
    },
    {
      question: 'Pourquoi choisir Legalstation pour créer mon entreprise ?',
      answer: `
          - Expertise approfondie des procédures administratives marocaines.
          - Gestion simplifiée et rapide de toutes les démarches.
          - Prix compétitifs et transparents.
          - Accompagnement personnalisé à chaque étape.
      
      Avec Legalstation, créer votre entreprise devient simple et sans stress.
      `,
    },
    {
      question: 'Pourquoi choisir les packs de Legalstation ?',
      answer: `
          - Gagnez du temps avec un service rapide et fluide.
          - Évitez les erreurs coûteuses grâce à nos experts.
          - Focus sur vos objectifs pendant que nous gérons la création.
          - Un rapport qualité-prix inégalé.
      
      Nos packs incluent toutes les démarches essentielles et des options premium pour des besoins spécifiques.
      `,
    },
  ];

  const displayedFaqs = showAll ? faqs : faqs.slice(0, 4); // Show only the first 4 questions if showAll is false

  return (
    <section className='frequentlyAskedQuestionsContainer py-16' ref={faqSectionRef}>
      <div className='frequentlyAskedQuestions'>
        <div className="frequentlyAskedQuestionsText text-center">
          <p className='title t1 text-lg text-gray-600'>Questions Fréquentes</p>
          <h2 className='title t2 text-4xl font-bold text-[#656565] mb-4'>
            Questions fréquentes sur la création d&apos;entreprise au Maroc
          </h2>
          <p className='title t3 text-gray-600 mb-12'>
            Réponses aux questions courantes concernant la création d&apos;entreprise, la gestion comptable et les services de conseil juridique.
          </p>
        </div>

        <div className="questions space-y-5">
          {displayedFaqs.map((faq, index) => (
            <div
              key={index}
              className={`faq-item border ${activeIndex === index
                ? 'border-opacity-100 border-white'
                : 'border-opacity-10'}
                question bg-[#070322] border border-opacity-10 border-white rounded-2xl relative group transition-all duration-300`}
            >
              <h2
                className="text-white flex items-center justify-between text-2xl cursor-pointer"
                onClick={() => toggleAccordion(index)}
              >
                {faq.question}
                <span>
                  {activeIndex === index ? (
                    <ArrowUpward sx={{ color: 'white' }} />
                  ) : (
                    <ArrowDownward sx={{ color: 'white' }} />
                  )}
                </span>
              </h2>
              {activeIndex === index && (
                <p className="mt-3 text-[#f9f6f6] whitespace-pre-wrap">
                  {faq.answer}
                </p>
              )}
            </div>
          ))}
        </div>

        {/* See More Button */}
        <div className="text-center flex justify-center mt-2">
          <Button
            className="button"
            variant="contained"
            size="large"
            color="primary"
            onClick={() => {
              setShowAll(!showAll);
              showAll ? scrollToTop() : scrollToBottom();
            }}
            endIcon={showAll ? <ArrowUpward /> : <ArrowDownward />}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: '10px 20px',
              backgroundColor: '#1e40af',
              '&:hover': {
                backgroundColor: '#3b82f6',
              },
            }}
          >
            {showAll ? 'Voir moins' : 'Voir plus'}
          </Button>
        </div>

      </div>
    </section>
  );
}
