import React, { useState, useEffect } from 'react';
import axios from 'axios';
import getEnvironement from '../../environnement';
import { toast, Bounce } from 'react-toastify';
import CircularProgress from '@mui/material/CircularProgress';

export default function SideBarPack({ activeTab, setActiveTab }) {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  const getCategories = async () => {
    const url = `${getEnvironement().API_URL}/admin/categories`;
    try {
      const response = await axios.get(url);
      setCategories(response.data);
    } catch (err) {
      console.error('Error fetching categories:', err.message);
      toast.error('Failed to fetch categories. Please try again later.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Bounce,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  const sections = [
    {
      title: 'D\'abord la création !',
      items: categories.filter((cat) =>
        ['Création d\'entreprise', 'Domiciliation d\'entreprise', '(Pack Marketing)'].includes(cat.name)
      ),
    },
    {
      title: 'Après la création, arrive la gestion',
      items: categories.filter((cat) =>
        [
          'Comptabilité et gestion des salariés',
          'Affaires juridiques et Documents juridiques',
          'Autres services: documents administratifs',
          'Parler à un expert juridique',
          'Consulter un avocat d’affaires',
        ].includes(cat.name)
      ),
    },
  ];

  return (
    <div className="bg-gradient2 w-[438px] pt-32">
      {loading ? (
        <div className="flex justify-center items-center h-full py-10">
          <CircularProgress />
        </div>
      ) : (
        sections.map((section, index) => (
          <div key={index} className="mb-10">
            <h2 className="font-[700] font-inter text-lg text-[#051259] px-10 mb-8">
              {section.title}
            </h2>
            {section.items.length > 0 ? (
              section.items.map((item) => (
                <p
                  key={item.id}
                  className={`leading-8 font-[400] font-inter text-[15.86px] text-[#051259] cursor-pointer px-10 hover:text-primary my-2 ${
                    activeTab === item.name ? 'text-primary' : ''
                  }`}
                  onClick={() => setActiveTab(item.name)}
                >
                  {item.name}
                </p>
              ))
            ) : (
              <p className="px-10 text-gray-500">No items available</p>
            )}
          </div>
        ))
      )}
    </div>
  );
}
