import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import CustomTextInput from '../../../../../../components/inputs/customTextInput/customTextInput';
import Button from '@mui/material/Button';
import { userSelector } from '../../../../../../store/userSlice';

function QEmailTel({ formData, setFormData, handlePreviousStep, handleSaveClick }) {
  const [errors, setErrors] = useState({});
  const user = useSelector(userSelector);

  useEffect(() => {
    // Set default values for email and phone when user data is available
    if (user) {
      setFormData((prev) => ({
        ...prev,
        email: user.email || prev.email || '',
        phone: user.phone || prev.phone || '',
      }));
    }
  }, [user, setFormData]);

  const validate = () => {
    const newErrors = {};
    if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Veuillez fournir une adresse e-mail valide.';
    }
    if (!formData.phone || !/^\+?\d{7,15}$/.test(formData.phone)) {
      newErrors.phone = 'Veuillez fournir un numéro de téléphone valide.';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = () => {
    if (validate()) {
      handleSaveClick();
    }
  };

  return (
    <div>
      <div className="bg-[#F6F7F9] rounded-lg px-8 py-4 mb-4">
        <div className="mb-5">
          <CustomTextInput
            type="email"
            value={formData.email || ''}
            setValue={(value) =>
              setFormData((prev) => ({
                ...prev,
                email: value,
              }))
            }
            placeholder="Email"
            error={errors.email}
          />
        </div>
        <div>
          <CustomTextInput
            type="tel"
            value={formData.phone || ''}
            setValue={(value) =>
              setFormData((prev) => ({
                ...prev,
                phone: value,
              }))
            }
            placeholder="Numéro de téléphone"
            error={errors.phone}
          />
        </div>
      </div>
      <div className="flex justify-center gap-5 mt-5">
        <Button color="info" variant="outlined" onClick={handlePreviousStep}>
          {'< Retour'}
        </Button>
        {user ? (
          <Button color="primary" variant="contained" onClick={handleSave}>
            Enregistrer
          </Button>
        ) : null}
      </div>
    </div>
  );
}

export default QEmailTel;
