import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import axios from 'axios';
import RecordsTable from './components/recordsTable';
import getEnvironement from '../../environnement';
import { userSelector } from '../../store/userSlice';
import searchIcon from '../../assets/icons/search-normal.png';
import DashHeader from './components/dashHeader';

function Records() {
  const user = useSelector(userSelector);
  const navigate = useNavigate();

  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');

  // Define fetchProducts outside the useEffect
  const fetchProducts = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${getEnvironement().API_URL}/admin/products/user/${user.id}`);
      setProducts(response.data);
    } catch (err) {
      setError('Failed to fetch products');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user?.id) {
      fetchProducts();
    }
  }, [user?.id]);

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
  };

  const filteredProducts = products.filter((product) =>
    product.product_id.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (error) return <p>{error}</p>;

  return (
    <div>
      <DashHeader picture={`${getEnvironement().BACKEND_URL}/storage/app/public/` + user?.profile_picture} name={user?.nom} />
      <div className="flex justify-between items-center mb-5">
        <p className="font-poppins font-medium text-2xl">Tous les produits</p>
        <Button variant="contained" onClick={() => navigate('/')}>
          + Ajouter un nouveau produit
        </Button>
      </div>
      <div className="flex w-1/3 h-10 p-2 mb-5 bg-[#FAFAFA] rounded-lg">
        <img src={searchIcon} alt="Search Icon" />
        <input
          type="search"
          placeholder="Chercher par ID"
          value={searchQuery}
          onChange={handleSearchChange}
          className="bg-[#FAFAFA] ml-2 p-2 focus:outline-none w-full"
        />
      </div>
      <RecordsTable
        header={['id-produit', 'type de produit', 'pack', 'Date', 'Status', 'Actions']}
        data={filteredProducts}
        loading={loading}
        fetchProducts={fetchProducts} 
      />
    </div>
  );
}

export default Records;
