import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import getEnvironement from '../../environnement';
import useFetch from './useFetch';

export default function DetailsArticle() {
  const { blogId } = useParams();
  const { loading, error, data } = useFetch(`${getEnvironement().BLOG_API_URL}?populate=*`);

  const [article, setArticle] = useState(null);
  const [sanitizedContent, setSanitizedContent] = useState('');

  const transformContentToHTML = (content) => {
    return content
      .map((item) => {
        if (item.type === 'paragraph') {
          return `<p>${item.children.map(child => child.text).join('')}</p>`;
        }
        if (item.type === 'heading') {
          return `<h${item.level}>${item.children.map(child => child.text).join('')}</h${item.level}>`;
        }
        if (item.type === 'list') {
          const listItems = item.children.map((child) => {
            return `<li>${child.children.map(subChild => subChild.text).join('')}</li>`;
          }).join('');
          return `<ul>${listItems}</ul>`;
        }
        if (item.type === 'ordered-list') {
          const listItems = item.children.map((child) => {
            return `<li>${child.children.map(subChild => subChild.text).join('')}</li>`;
          }).join('');
          return `<ol>${listItems}</ol>`;
        }
        if (item.type === 'link') {
          return `<a href="${item.url}" target="${item.target}">${item.children.map(child => child.text).join('')}</a>`;
        }
        if (item.type === 'image') {
          return `<img src="${item.url}" alt="${item.alt}" />`;
        }
        if (item.type === 'blockquote') {
          return `<blockquote>${item.children.map(child => child.text).join('')}</blockquote>`;
        }
        if (item.type === 'strong') {
          return `<strong>${item.children.map(child => child.text).join('')}</strong>`;
        }
        if (item.type === 'em') {
          return `<em>${item.children.map(child => child.text).join('')}</em>`;
        }
        if (item.type === 'underline') {
          return `<u>${item.children.map(child => child.text).join('')}</u>`;
        }
        if (item.type === 'strike') {
          return `<s>${item.children.map(child => child.text).join('')}</s>`;
        }
        if (item.type === 'subscript') {
          return `<sub>${item.children.map(child => child.text).join('')}</sub>`;
        }
        if (item.type === 'superscript') {
          return `<sup>${item.children.map(child => child.text).join('')}</sup>`;
        }
        return ''; // Handle other types if necessary
      })
      .join('');
  };

  useEffect(() => {
    if (data?.data) {
      const foundArticle = data.data.find((item) => item.id === parseInt(blogId, 10));
      setArticle(foundArticle);

      if (foundArticle?.blogContent) {
        const contentHTML = transformContentToHTML(foundArticle.blogContent);
        setSanitizedContent(
          DOMPurify.sanitize(contentHTML, {
            ALLOWED_TAGS: [
              'p', 'br', 'strong', 'em', 'b', 'i', 'u', 's', 'sup', 'sub', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'ul', 'ol', 'li',
              'blockquote', 'figure', 'figcaption', 'a', 'img', 'div', 'span',
            ],
            ALLOWED_ATTR: ['href', 'src', 'alt', 'title', 'style'],
          })
        );
      }
    }
  }, [data, blogId]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;
  if (!article) return <p>Article not found.</p>;

  return (
    <div className="min-h-screen flex flex-col justify-between pt-16">
      <Header />
      <div className="bg-gradient p-10 ">
        <div className="text-center mb-5">
          {/* Display article title */}
          {article.blogTitle ? (
            <h1 className="text-4xl font-semibold ">{article.blogTitle}</h1>
          ) : (
            <p>Article not available</p>
          )}
        </div>
        <div className="my-5 flex justify-center">
          {/* Display cover image with styling */}
          {article.coverImage?.url && (
            <img
              src={`${getEnvironement().BLOG_URL}${article.coverImage.url}`}
              alt="article"
              className="max-w-4xl w-full h-auto rounded-lg shadow-lg"
            />
          )}
        </div>

      </div>
      <div className='p-10 pt-0 mb-10'>
        <div className="my-10 px-4 sm:px-10 flex justify-center">
          {/* Display sanitized article content */}
          <div
            className="w-full sm:w-4/5 text-lg sm:text-xl font-poppins rich-text leading-relaxed text-gray-800"
            dangerouslySetInnerHTML={{ __html: sanitizedContent }}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}
