import React, { useState, useEffect } from 'react';
import CustomTextInput from '../../../../../../components/inputs/customTextInput/customTextInput';
import Personne from './Personne';
import Button from '@mui/material/Button';

export default function QSoussignes({
  formData = { soussignes: [] }, // Initialize soussignes as an empty array by default
  setFormData,
  handleNextStep,
  handlePreviousStep,
}) {
  const [errors, setErrors] = useState({});

  const getDefaultSoussigne = (isFirst = false) => {
    if (isFirst && formData.typeBeneficiaire === 'Personne physique') {
      return {
        type: 'Personne physique',
        nom: formData.nomBeneficiaire || '',
        prenom: formData.prenomBeneficiaire || '',
        nationalite: formData.nationaliteBeneficiaire || '',
        cin: formData.cinBeneficiaire || '',
        dateBirth: formData.dateBirthBeneficiaire || '',
        email: formData.emailBeneficiaire || '',
        gsm: formData.gsmBeneficiare || '',
        adresse: formData.adresseBeneficiaire || '',
      };
    } else if (isFirst && formData.typeBeneficiaire === 'Personne morale') {
      return {
        type: 'Personne morale',
        denomination: formData.denominationBeneficiaire || '',
        ice: formData.iceBeneficiaire || '',
        rc: formData.rcBeneficiaire || '',
        adresseSiege: formData.adresseBeneficiaire || '',
      };
    }

    return formData.typeBeneficiaire === 'Personne physique'
      ? {
        type: 'Personne physique',
        nom: '',
        prenom: '',
        nationalite: '',
        cin: '',
        dateBirth:'',
        email: '',
        gsm: '',
        adresse: '',
      }
      : {
        type: 'Personne morale',
        denomination: '',
        ice: '',
        rc: '',
        adresseSiege: '',
      };
  };
  useEffect(() => {
    setFormData((prev) => {
      const updatedSoussignes = Array.isArray(prev.soussignes) ? [...prev.soussignes] : [];
      if (updatedSoussignes.length > 0) {
        updatedSoussignes[0].data = getDefaultSoussigne(true); // Pre-fill the first soussigné
      } else {
        updatedSoussignes.push({ id: 1, data: getDefaultSoussigne(true) });
      }
      return {
        ...prev,
        soussignes: updatedSoussignes,
      };
    });
  }, [formData.typeBeneficiaire]);

  const { nombreSoussignes = 1, soussignes = [] } = formData;

  const updateField = (key, value) => {
    setFormData((prev) => ({ ...prev, [key]: value }));
  };

  const validateInputs = () => {
    const newErrors = {};
    const emailSet = new Set();
    const cinSet = new Set();
    const gsmSet = new Set();
  
    soussignes.forEach(({ id, data }) => {
      const soussigneErrors = {};
  
      if (data.type === 'Personne physique') {
        const requiredFields = ['nom', 'dateBirth','prenom', 'nationalite', 'cin', 'gsm', 'email', 'adresse'];
        requiredFields.forEach((field) => {
          if (!data[field]?.trim()) {
            soussigneErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} est requis.`;
          }
        });
        if (data.gsm && !/^\+?[0-9]{10,15}$/.test(data.gsm)) {
          soussigneErrors.gsm = 'Le GSM est invalide.';
        }
        if (data.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email)) {
          soussigneErrors.email = 'L\'E-mail est invalide.';
        }
  
        // Check for duplicate CIN, email, and GSM
        if (data.cin && cinSet.has(data.cin)) {
          soussigneErrors.cin = 'Le CIN doit être unique.';
        } else {
          cinSet.add(data.cin);
        }
        
        if (data.email && emailSet.has(data.email)) {
          soussigneErrors.email = 'L\'E-mail doit être unique.';
        } else {
          emailSet.add(data.email);
        }
        if (data.gsm && gsmSet.has(data.gsm)) {
          soussigneErrors.gsm = 'Le GSM doit être unique.';
        } else {
          gsmSet.add(data.gsm);
        }

      } else if (data.type === 'Personne morale') {
        const requiredFields = ['denomination', 'ice', 'rc', 'adresseSiege'];
        requiredFields.forEach((field) => {
          if (!data[field]?.trim()) {
            soussigneErrors[field] = `${field.charAt(0).toUpperCase() + field.slice(1)} est requis.`;
          }
        });
      }
  
      if (Object.keys(soussigneErrors).length > 0) {
        newErrors[id] = soussigneErrors;
      }
    });
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  

  const handleContinue = () => {
    if (validateInputs()) {
      handleNextStep({
        nombreSoussignes,
        soussignes,
      });
    }
  };
  const handleChangeNombreSoussignes = (value) => {
    const parsedValue = parseInt(value, 10) || 1; // Default to 1 if invalid input
    updateField('nombreSoussignes', parsedValue);

    const adjustedSoussignes = Array.from({ length: parsedValue }, (_, i) => ({
      id: i + 1,
      data: soussignes[i]?.data || getDefaultSoussigne(), // Retain existing data or initialize empty
    }));

    updateField('soussignes', adjustedSoussignes);
  };


  return (
    <div className="mb-10">
      <div className="bg-[#F6F7F9] rounded-lg pl-4 py-4 pr-2 mb-4">
        <CustomTextInput
          type="number"
          value={nombreSoussignes}
          setValue={handleChangeNombreSoussignes}
          placeholder="Nombre de soussignés"
          error={errors.nombreSoussignes}
        />
      </div>

      {soussignes && soussignes.map(({ id, data }) => (
        <div key={id} className="bg-[#F6F7F9] rounded-lg pl-4 py-4 pr-2 mb-4">
          <Personne
            title={`Soussigné ${id}`}
            data={data}
            setPersonne={(updatedPersonne) => {
              const updatedSoussignes = soussignes.map((soussigne) =>
                soussigne.id === id ? { ...soussigne, data: updatedPersonne } : soussigne
              );
              updateField('soussignes', updatedSoussignes);
            }}
            errors={errors[id] || {}}
          />
        </div>
      ))}


      <div className="flex justify-center gap-5 mt-5">
        <Button color="info" variant="outlined" onClick={handlePreviousStep}>
          {'< Retour'}
        </Button>
        <Button color="primary" variant="contained" onClick={handleContinue}>
          Continuer
        </Button>
      </div>
    </div>
  );
}
