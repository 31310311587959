import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, IconButton, Tooltip , CircularProgress } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PaymentsIcon from '@mui/icons-material/Payments';
import DeleteIcon from '@mui/icons-material/Delete';
// import { useSpring } from '@react-spring/web';
import { format } from 'date-fns';
import axios from 'axios';
import productIdIcon from '../../../assets/icons/productIdIcon.png';
import getEnvironement from '../../../environnement';
import { setUser, userSelector } from '../../../store/userSlice';
import DetailsProduct from './detailsProduct';
// import documentIcon from '../../../assets/icons/documentIcon.png';
// import DocumentsBox from './documentsBox';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentDrawer from './payment-drawer';

function RecordsTable({
  header,
  data,
  loading,
  fetchProducts
}) {

  const stripePromise = loadStripe('your-public-key-here');


  const [openDrawer, setOpenDrawer] = useState(false);
  const [activeProduct, setActiveProduct] = useState('');

  const user = useSelector(userSelector);
  const dispatch = useDispatch();
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const handleOpenModal = () => {
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const getStatus = (status, index) => {
    if (status.status === 'Rejetée') {
      return (
        <p
          key={index}
          className="rounded-full bg-[#FDB9BC] font-poppins font-normal text-sm/[16px] text-center py-1 m-1 text-[#F04148]"
        >
          Rejetée
        </p>
      );
    } else if (status.status === 'Validée') {
      return (
        <p
          key={index}
          className="rounded-full bg-[#CAFFBD] font-poppins font-normal text-sm/[16px] text-center py-1 m-1 text-[#35DE0A]"
        >
          Validée
        </p>
      );
    } else {
      return (
        <Tooltip key={index} title={status.status}>
          <p
            className="rounded-full bg-[#E5E5E5] font-poppins font-normal text-sm/[16px] text-center py-1 m-1 text-[#000000]"
          >
            {`${status.status.split(' ')[0]} ${status.status.split(' ')[1] || ''} ...`}
          </p>
        </Tooltip>
      );
    }
  };

  const isDemandeAnnulation = (status) => {
    let isDemandeAnnulation = false;

    if (status.status === 'Validée') {
      isDemandeAnnulation = true;
      return;
    }

    return isDemandeAnnulation;
  };

  const getProductName = (product_id) => {
    const productsName = {
      'CE': 'entreprise',
      'CS': 'monStatus',
      'CA': 'associations',
      'CAE': 'autoEntreprise',
      'CG': 'carteGrise',
      'CHS': 'changementStatut',
      'C': 'cession',
      'D': 'dissolution',
    };
    return productsName[product_id.split('-')[0]];
  };

  const deleteProduct = async (product_id, isDemandeAnnulation) => {
    const url = `${getEnvironement().API_URL}/${getProductName(product_id)}/${product_id}`;
    const data = {
      user_id: user.id,
      isDemandeAnnulation,
    };
    await axios.delete(url, { data })
      .then((response) => {
        if (response.status === 200) {
          dispatch(setUser(response.data));
        }
      });
  };

  const formatPrice = (value) => {
    return new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'MAD',
    })
      .format(value)
      .replace('MAD', 'DH'); 
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      {/* <DocumentsBox
        open={open}
        setOpen={setOpen}
        springs={springs}
        productId={activeProduct}
        filePathes={filePathes}
      /> */}
      <Drawer
        open={openDrawer}
        anchor='left'
        onClose={() => setOpenDrawer(false)}
      >
        <div className='w-[80vw]'>
          <DetailsProduct
            product={data.filter((product) => product.product_id === activeProduct)[0]}
            setOpenDrawer={setOpenDrawer}
            fetchProducts={fetchProducts}
          />
        </div>
      </Drawer>
      <table className='table-auto w-full'>
        <thead>
          <tr className='border-solid border-b border-[#EBEBEB]'>
            {header.map((item, index) => {
              return (
                <th
                  key={item}
                  className={`font-poppins font-medium text-sm/[16px] opacity-50 py-5 ${index === 0 && 'text-start w-24'}`}
                >
                  {item}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>

          {data.map((item, index) => {
            return (

              <tr key={index} className='border-solid border-b border-[#EBEBEB]'>

                <td className='min-w-32 md:min-w-none flex py-2 items-center font-poppins font-normal text-sm/[16px]'>
                  <img src={productIdIcon} />
                  <p className='ml-3 max-w-14 break-words'>{item.product_id}</p>
                </td>
                <td className='font-poppins font-normal text-sm/[16px] text-center opacity-50'>{item.type}</td>
                <td className='font-poppins font-normal text-sm/[16px] text-center'>{item.pack?.type} - {formatPrice(item.pack?.price)}</td>
                <td className='font-poppins font-normal text-sm/[16px] text-center'>{format(new Date(item.created_at), 'yyyy-MM-dd HH:mm')}</td>
                <td>{getStatus(item.status)}</td>
             
                <td className='py-5 text-center'>
                  <Tooltip title='Documents'>
                    <IconButton onClick={() => {
                      setActiveProduct(item.product_id);
                      setOpenDrawer(true);
                    }}>
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                  {!item.is_payed && <Tooltip title='Acheter ce pack'>
                    <IconButton onClick={handleOpenModal}>
                      <PaymentsIcon />
                    </IconButton>

                  </Tooltip>}
                  <Tooltip title={isDemandeAnnulation(item.status) ? 'Demande d\'annulation' : 'Annuler'}>
                    <IconButton onClick={() => deleteProduct(item.product_id, isDemandeAnnulation(item.status))}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </td>
                <Elements stripe={stripePromise}>
                  <PaymentDrawer
                    open={isDrawerOpen}
                    onClose={handleCloseDrawer}
                    product={item}
                    user={user}
                    formatPrice={formatPrice}
                    getEnvironement={getEnvironement}
                  />
                </Elements>
              </tr>

            );
          })}
        </tbody>
      </table>

      {data.length === 0 && (
        <div className='rounded border border-dashed border-1 opacity-50 p-5 m-5 text-center font-poppins font-normal text-sm/[16px]'>Aucun enregistrement</div>
      )}
    </>
  );
}

export default RecordsTable;