import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { BlobProvider } from '@react-pdf/renderer';
import PDFDocument from './pdfDocument';
import questions from './questions';
import StepProgress from '../../../../components/stepProgress/stepProgress';
import HeaderContainer from '../../../../components/headerContainer/headerContainer';
import getEnvironement from '../../../../environnement';
import { setUser, userSelector } from '../../../../store/userSlice';

export default function SarlForm() {
  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector(userSelector);
  const dispatch = useDispatch();

  // Load saved form data and current step from localStorage
  useEffect(() => {
    const savedFormData = localStorage.getItem('sarlFormData');
    const savedStep = localStorage.getItem('sarlCurrentStep');
    const { domaineActivite } = location.state || {}; // Check for domaineActivite

    if (savedFormData) {
      const parsedFormData = JSON.parse(savedFormData);
      // Include domaineActivite if it's available
      if (domaineActivite) {
        parsedFormData.domaineActivite = domaineActivite;
      }
      setFormData(parsedFormData);
    } else if (domaineActivite) {
      // If there's no saved form data, initialize with domaineActivite
      setFormData((prevData) => ({
        ...prevData,
        domaineActivite, // Add domaineActivite to formData
      }));
    }

    if (savedStep) {
      setCurrentStep(parseInt(savedStep, 10));
    }
  }, [location.state]);

  // Save formData and currentStep to localStorage
  useEffect(() => {
    localStorage.setItem('sarlFormData', JSON.stringify(formData));
    localStorage.setItem('sarlCurrentStep', currentStep.toString());
  }, [formData, currentStep]);

  const saveData = async (pdfBlob) => {
    if (!user || !user.id) {
      navigate('/login'); // Redirect to login page
      return;
    }

    const url = `${getEnvironement().API_URL}/entreprise`;
    const data = new FormData();
    data.append('user_id', user.id);

    Object.entries(formData).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item, index) => {
          data.append(`${key}[${index}]`, JSON.stringify(item));
        });
      } else {
        data.append(key, value);
      }
    });

  

    data.append('pdf', pdfBlob, 'document.pdf');

    try {
      const response = await axios.post(url, data, { headers: { 'Content-Type': 'multipart/form-data' } });
      if (response.status === 200) {
        dispatch(setUser(response.data));
        navigate('/dashboard');
      }
    } catch (error) {
      console.error('Error saving data:', error);
    }
  };

  const handleNextStep = (stepData) => {
    setFormData((prevData) => ({ ...prevData, ...stepData }));
    setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
    if (currentStep > 1) setCurrentStep(currentStep - 1);
  };

  const handleSaveClick = (blob) => {
    saveData(blob);
    localStorage.removeItem('sarlFormData');
    localStorage.removeItem('sarlCurrentStep');
  };

  const handleLoginRedirect = () => {
    localStorage.setItem('referrer', `${window.location.pathname}maSociete/form`);
    navigate('/login');
  };

  const CurrentQuestion = questions[currentStep - 1]?.component;

  return (
    <div>
      <HeaderContainer />
      <div className="min-h-screen flex flex-col justify-start items-center pt-24 px-4 sm:px-8 mt-6 sm:mt-10">
        <h3 className="text-lg sm:text-xl font-semibold text-center mb-4">
          CREATION D&apos;UNE ENTREPRISE
        </h3>
        <StepProgress numberSteps={questions.length} currentStep={currentStep} />
        <BlobProvider document={<PDFDocument {...formData} />}>
          {({ blob }) => (
            <div className="w-full max-w-[90%] sm:max-w-[700px] md:max-w-[950px] mt-6">
              {!user || !user.id ? (
                <div className="bg-[#FFF4E5] border-l-4 border-[#FFA500] text-[#D97706] p-4 rounded mb-6">
                  <div className="flex items-center">
                    <svg
                      className="h-6 w-6 text-[#D97706] mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 16h-1v-4h.01M12 9h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    <p className="font-medium">
                      Veuillez vous connecter ou vous inscrire pour soumettre le formulaire.{' '}
                      <span
                        onClick={handleLoginRedirect}
                        className="text-[#2563EB] hover:underline cursor-pointer"
                      >
                        Aller à la connexion.
                      </span>
                    </p>
                  </div>
                </div>
              ) : null}

              {CurrentQuestion ? (
                <CurrentQuestion
                  formData={formData}
                  setFormData={setFormData}
                  handleNextStep={handleNextStep}
                  handlePreviousStep={handlePreviousStep}
                  handleSaveClick={() => handleSaveClick(blob)}
                />
              ) : (
                <div className="text-center text-gray-500">Fin du formulaire</div>
              )}

              {!user || !user.id ? (
                <div className="bg-[#FFF4E5] border-l-4 border-[#FFA500] text-[#D97706] p-4 rounded mb-6">
                  <div className="flex items-center">
                    <svg
                      className="h-6 w-6 text-[#D97706] mr-2"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 16h-1v-4h.01M12 9h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    <p className="font-medium">
                      Veuillez vous connecter ou vous inscrire pour soumettre le formulaire.{' '}
                      <span
                        onClick={handleLoginRedirect}
                        className="text-[#2563EB] hover:underline cursor-pointer"
                      >
                        Aller à la connexion.
                      </span>
                    </p>
                  </div>
                </div>
              ) : null}
            </div>
          )}
        </BlobProvider>
      </div>
    </div>
  );
}
