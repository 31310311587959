import React from 'react';
import PropTypes from 'prop-types';

export default function StepProgress({ numberSteps, currentStep }) {
  const getStepsList = (numberStepsValue) => {
    return Array.from({ length: numberStepsValue }, (_, i) => i + 1);
  };

  return (
    <div
      className="flex items-center justify-start mt-5 mb-12 w-full max-w-[90%] md:max-w-[700px] overflow-x-auto"
      role="progressbar"
      aria-valuenow={currentStep}
      aria-valuemin={1}
      aria-valuemax={numberSteps}
    >
      {getStepsList(numberSteps).map((item, index) => (
        <React.Fragment key={item}>
          <Step isActive={item <= currentStep} isDone={item < currentStep} />
          {index < numberSteps - 1 && (
            <div
              className={`flex-1 h-[2px] md:h-[4px] ${item < currentStep ? 'bg-primary' : 'bg-[#D5D8E2]'
              }`}
            />
          )}
        </React.Fragment>
      ))}
    </div>
  );
}

function Step({ isActive = false, isDone = false }) {
  return (
    <div
      className={`relative w-[15px] h-[15px] md:w-[30px] md:h-[30px] lg:w-[40px] lg:h-[40px] rounded-full transition-all duration-300 flex items-center justify-center 
        ${isActive ? 'bg-primary' : 'bg-[#D5D8E2] border-4 border-[#D5D8E2]'}`}
    >
      {isDone && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="white"
          className="w-2 h-2 md:w-4 md:h-4 lg:w-6 lg:h-6"
        >
          <path d="M20.285 6.709a1 1 0 00-1.41-1.418L9.934 14.237l-3.797-3.798a1 1 0 00-1.414 1.415l4.504 4.503a1 1 0 001.414 0l9.644-9.648z" />
        </svg>
      )}
    </div>
  );
}

StepProgress.propTypes = {
  numberSteps: PropTypes.number.isRequired,
  currentStep: PropTypes.number.isRequired,
};

Step.propTypes = {
  isActive: PropTypes.bool,
  isDone: PropTypes.bool,
};
