import FR_LANG from './views/admin/utils/lang/fr';

const ENVIRONMENT = {
  developpement: {
    API_URL: 'http://127.0.0.1:8000/api',
    BACKEND_URL: 'http://127.0.0.1:8000',

    BLOG_API_URL: 'https://legalstation-blog.luceomtechnology.com',
    BLOG_URL: 'https://legalstation-blog.luceomtechnology.com',
    REACT_APP_STRIPE_PUBLIC_KEY: 'your-publishable-key-here',
  },
  production: {
    API_URL: 'https://legalstationapi.luceomtechnology.com/api',
    BACKEND_URL: 'https://legalstationapi.luceomtechnology.com',
    BLOG_API_URL: 'https://legalstation-blog.luceomtechnology.com/api/blogs',
    BLOG_URL: 'https://legalstation-blog.luceomtechnology.com',
    REACT_APP_STRIPE_PUBLIC_KEY:'your-publishable-key-here',

  },
};

const PLATFORM = 'production';

export function getLang(lang) {
  if (lang == 'FR') {
    return FR_LANG;
  }
}

export default function getEnvironement() {
  return ENVIRONMENT[PLATFORM];
}
