import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Button, IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logoLegalStation from '../../assets/images/LOGO-LS@2x.png';
import { userSelector } from '../../store/userSlice';

import './header.css';

export default function Header({ active }) {
  const [activeMenu, setActiveMenu] = useState(false);
  const [startAnimationDisactivateMenu, setStratAnimationDisactivateMenu] = useState(false);
  const [activeItem, setActiveItem] = useState(active);
  const user = useSelector(userSelector);

  const menuItems = [
    ['Acceuil', '/'],
    ['Créer une entreprise', '/creationEntreprise'],
    ['About', '/apropos'],
    ['Packs', '/packs'],
    ['Blogs', '/blogs'],
    ['Contact', '/contact'],
  ];

  return (
    <div className="headerContainer  pb-4 px-5">
      <div className="header">
        <Link to="/">
          <img className="logoLegalStation" src={logoLegalStation} alt="logo-legal-station" />
        </Link>

        {/* Menu Icon for Mobile */}
        <div className="buttonMenuIcon">
          <IconButton
            aria-label="Toggle menu"
            aria-expanded={activeMenu}
            aria-controls="mobile-menu"
            onClick={() => {
              setActiveMenu(!activeMenu);
              setStratAnimationDisactivateMenu(true);
            }}
          >
            <MenuIcon className="menuIcon" style={{ fontSize: 40, color: 'black' }} />
          </IconButton>
        </div>

        {/* Menu Items for Larger Screens */}
        <div className="menuItemsContainer-1024 rounded-fully flex justify-center gap-4">
          {menuItems.map(([item, link]) => (
            <Link
              className={`item ${activeItem === item && 'activeItem'}`}
              onClick={() => {
                setActiveItem(item);
                window.scrollTo(0, 0);
              }}
              key={item}
              to={link}
            >
              {item}
            </Link>
          ))}
        </div>

        {/* Login Button */}
        {!user || !user.id ? (
          <Link className="login_button" to="/login">
            <Button variant="contained">Connexion</Button>
          </Link>
        ) : <Link className="login_button" to="/dashboard">
          <Button variant="contained">Tableau De Bord</Button>
        </Link>}
      </div>

      {/* Mobile Menu Items */}
      <div
        id="mobile-menu"
        className={`menuItemsContainer-767 ${activeMenu
          ? 'menuItemsContainerActive'
          : startAnimationDisactivateMenu
            ? 'menuItemsContainerDisactivate'
            : ''
        }`}
      >
        {menuItems.map(([item, link]) => (
          <Link
            className={`item ${activeItem === item && 'activeItem'}`}
            onClick={() => {
              setActiveItem(item);
              window.scrollTo(0, 0);
            }}
            key={item}
            to={link}
          >
            {item}
          </Link>
        ))}
        <Link
          className={`item ${activeItem === 'login' && 'activeItem'}`}
          onClick={() => {
            setActiveItem('login');
            window.scrollTo(0, 0);
          }}
          key="login"
          to="/login"
        >
          Connexion
        </Link>
      </div>
    </div>
  );
}
