import React from 'react';
import startYourBusinessImg from '../../../../assets/images/startYourBusinessImg.svg';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import './startYourBusiness.css';

const StartYourBusiness = () => {
  const navigate = useNavigate();
  const navigateToDashboard = () => {
    navigate('/dashboard');
  };
  return (
    <section className="startYourBusinessContainer">
      <div className="startYourBusiness">
        <div className="image-side">
          <img src={startYourBusinessImg} alt="Start Your Business" />
        </div>
        <div className="content-side">
          <h1>Prêt à démarrer votre entreprise ?</h1>
          <p>Créez-la dès aujourd’hui en ligne avec Legalstation.ma</p>
          <Button className="button" variant="contained" size="large" onClick={navigateToDashboard}>
            Démarrez votre projet !
          </Button>
        </div>
      </div>
    </section>
  );
};

export default StartYourBusiness;
