import React, { useEffect, useMemo, useState } from 'react';
import CustomSelect from '../../../../../../components/inputs/customSelect';
import CustomTextInput from '../../../../../../components/inputs/customTextInput/customTextInput';
import { extraireNoms } from '../../utils';

export default function Apport({
  id,
  soussignes,
  setApports,
  apportData = {},
  error = {},
  apports,
}) {
  const [soussigne, setSoussigne] = useState(apportData.soussigne || '');
  const [apport, setApport] = useState(apportData.apport || 0);
  const [autreApport, setAutreApport] = useState(apportData.autreApport || '');

  useEffect(() => {
    setApports({
      id,
      soussigne,
      apport: parseFloat(apport) || 0,
      autreApport,
    });
  }, [soussigne, apport, autreApport, id, setApports]);

  const availableSoussignes = useMemo(
    () =>
      soussignes.filter(
        (soussigneItem) =>
          !apports.some(
            (apportItem) =>
              apportItem.soussigne === soussigneItem && apportItem.id !== id
          )
      ),
    [soussignes, apports, id]
  );

  return (
    <div className="bg-[#F6F7F9] rounded-lg pl-4 py-4 pr-2 mb-4">
      <p className="text-base font-medium text-neutral mb-2">Apport {id}</p>
      <div className="flex flex-col md:flex-row gap-4">
        <CustomSelect
          value={soussigne}
          setValue={setSoussigne}
          items={extraireNoms(availableSoussignes)}
          placeholder="Sélectionnez un soussigné"
          error={error.soussigne}
        />
        <CustomTextInput
          type="number"
          min={0}
          max={100}
          value={apport}
          setValue={setApport}
          placeholder="Apport en pourcentage"
          error={error.apport}
        />
      </div>
      <CustomTextInput
        value={autreApport}
        setValue={setAutreApport}
        placeholder="Autres apports (optionnel)"
      />
    </div>
  );
}
