import React from 'react';
import Header from '../../components/header/header';
import NavToForm from './components/navToForm/navToForm';
import HeaderTitles from './components/headerTitles/headerTitles';
import PourquoiLegalStation from './components/pourquoiLegalStation/pourquoiLegalStation';
import Services from './components/services/services';
import CustomersFeedback from './components/customers-feedback/customersFeedback';
import FrequentlyAskedQuestions from './components/FrequentlyAskedQuestions/frequentlyAskedQuestions';

import DernierArticles from './components/dernierArticles/dernierArticles';
import StartYourBusiness from './components/startYourBusiness/startYourBusiness';
import Footer from '../../components/footer/footer';
import './legalStationLandingView.css';
import ContactUs from './components/contactUs/contactUs';

export default function LegalStationLandingView() {
  return (
    <div className='legalStationLandingContainer'>
      <Header active='Acceuil' />
      <HeaderTitles />
      <NavToForm />
      <PourquoiLegalStation />
      <Services />
      <CustomersFeedback />
      <ContactUs />
      <FrequentlyAskedQuestions />
      <DernierArticles />
      <StartYourBusiness />
      <Footer />
    </div>
  );
}
