import React from 'react';

import './pourquoiLegalStation.css';
import pourquoiLegalStationBg from '../../../../assets/images/pourquoiLegalStationBg.svg';
import pourquoiLegalStation from '../../../../assets/images/pourquoiLegalStation.svg';

export default function PourquoiLegalStation() {
  return (
    <section className="pourquoiLegalStationContainer">
      <div
        className="pourquoiLegalStation"
        style={{
          marginTop: '40px',
         
          backgroundImage: `url(${pourquoiLegalStationBg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className="pourquoiLegalStationText text-center py-8">
          <p className='title t1'>
            Simplicité et efficacité          
          </p>
          <h1>Pourquoi choisir Legalsation.ma ?</h1>
        </div>

        <img className='process' src={pourquoiLegalStation} />


      </div>
    </section>
  );
}
