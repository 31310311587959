import React, { useEffect, useState } from 'react';
import CustomTextInput from '../../../../../../components/inputs/customTextInput/customTextInput';
import Apport from './Apport';
import { range, supprimerDerniersItems } from '../../utils';
import { Button, Snackbar, Alert } from '@mui/material';

export default function QApports({
  formData = {
    apports: [],
    soussignes: [],
    capitalSocial: '',
  },
  setFormData,
  handleNextStep,
  handlePreviousStep,
}) {
  const { apports = [], nombreApports = formData.soussignes?.length || 0, capitalSocial = '' } =
    formData;
  const [errors, setErrors] = useState({});
  const [totalPercentage, setTotalPercentage] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    const updatedApports = range(nombreApports).map((id) => {
      const existingData = apports.find((item) => item.id === id);
      return existingData || { id, soussigne: '', apport: 0, autreApport: '' };
    });

    setFormData((prev) => ({
      ...prev,
      apports: supprimerDerniersItems(updatedApports, nombreApports),
    }));

    setErrors({});
  }, [nombreApports, apports, setFormData]);

  useEffect(() => {
    const total = apports.reduce((sum, apport) => sum + (parseFloat(apport.apport) || 0), 0);
    setTotalPercentage(total);
  }, [apports]);

  const modifyApportsData = (id, apport) => {
    const updatedApports = [...apports];
    updatedApports[id - 1] = apport;
    setFormData((prev) => ({ ...prev, apports: updatedApports }));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!capitalSocial || isNaN(capitalSocial) || capitalSocial <= 0) {
      newErrors.capitalSocial = 'Le montant du capital social est requis et doit être supérieur à 0.';
    }

    if (totalPercentage !== 100) {
      newErrors.totalPercentage = 'La somme des apports doit être égale à 100%.';
    }

    apports.forEach((apport, index) => {
      if (!apport.soussigne) {
        newErrors[`soussigne_${index}`] = `Apport ${index + 1}: Le soussigné est requis.`;
      }
      if (!apport.apport || apport.apport <= 0) {
        newErrors[`apport_${index}`] = `Apport ${index + 1}: Le montant est requis et doit être supérieur à 0.`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    if (validateForm()) {
      handleNextStep({ capitalSocial, nombreApports, apports });
    } else {
      setSnackbarMessage('Veuillez corriger les erreurs avant de continuer.');
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => setOpenSnackbar(false);

  return (
    <div className="mb-10">
      <div className="bg-[#F6F7F9] rounded-lg pl-4 py-4 pr-2 mb-4">
        <CustomTextInput
          type="number"
          min={0}
          placeholder="Montant du capital social"
          value={capitalSocial}
          setValue={(value) => setFormData((prev) => ({ ...prev, capitalSocial: value }))}
          error={errors.capitalSocial}
        />
      </div>
      <div className="bg-[#F6F7F9] rounded-lg pl-4 py-4 pr-2 mb-4">
        <p className="text-base font-medium text-neutral mb-2">Ajouter / Supprimer un apport:</p>
        <CustomTextInput
          type="number"
          min={0}
          value={nombreApports}
          setValue={(value) => setFormData((prev) => ({ ...prev, nombreApports: value }))}
        />
      </div>

      {apports.map((apport) => (
        <Apport
          key={apport.id}
          id={apport.id}
          apportData={apport}
          soussignes={formData.soussignes}
          apports={apports}
          setApports={(newApport) => modifyApportsData(apport.id, newApport)}
          error={{
            apport: errors[`apport_${apport.id - 1}`],
            soussigne: errors[`soussigne_${apport.id - 1}`],
          }}
        />
      ))}
      <Alert severity="info" className="mt-2 rounded-[10px]">
        <p className="text-sm text-gray-500">
          Somme actuelle des apports: <strong>{totalPercentage}%</strong>
        </p>
      </Alert>
      {errors.totalPercentage && (
        <Alert severity="error" className="mt-2">
          {errors.totalPercentage}
        </Alert>
      )}
      <div className="flex justify-center gap-5 mt-5">
        <Button color="info" variant="outlined" onClick={handlePreviousStep}>
          {'< Retour'}
        </Button>
        <Button color="primary" variant="contained" onClick={handleNext}>
          Continuer
        </Button>
      </div>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}
