import React, { useState } from 'react';
import {
  Drawer,
  Typography,
  Button,
  TextField,
  FormControlLabel,
  Radio,
  RadioGroup,
  Checkbox,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
  Stack,
  IconButton
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import app_logo from '../../../assets/images/LOGO-LS@2x.png';

import axios from 'axios';

import {
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

const PaymentDrawer = ({
  open, onClose, product, user, formatPrice, getEnvironement
}) => {
  const [paymentMethod, setPaymentMethod] = useState('stripe');
  const [paymentDetails, setPaymentDetails] = useState({});
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showFullTerms, setShowFullTerms] = useState(false);

  const handlePayment = async () => {
    if (!termsAccepted) {
      alert('Vous devez accepter les termes et conditions avant de continuer.');
      return;
    }
  
    const payload = {
      product_id: product.id,
      productId: product.product_id,
      user_id: user.id,
      payment_method: paymentMethod,
      payment_details: JSON.stringify({
        ...paymentDetails,
        amount: product.pack?.price
      }),
    };
  
    try {
      const response = await axios.post(`${getEnvironement().API_URL}/admin/payments/store`, payload);
      if (response.status === 200) {
        alert('Paiement effectué avec succès');
        onClose();
      }
    } catch (error) {
      console.error('Échec du paiement :', error);
      alert('Échec du paiement. Veuillez réessayer.');
    }
  };
  

  const renderPaymentInputs = () => {
    const stripe = useStripe();
    const elements = useElements();
  
    switch (paymentMethod) {
    case 'stripe':
      return (
        <Box sx={{
          maxWidth: 400, margin: 'auto', padding: 3, backgroundColor: '#f9f9f9', borderRadius: 2, boxShadow: 2 
        }}>
          <Typography 
            variant="subtitle1" 
            sx={{ mb: 2, fontWeight: 600, fontSize: '1.1rem', color: '#333' }}>
              Entrez les détails de votre carte bancaire :
          </Typography>
          
          {/* Card Element for secure card entry */}
          <Box sx={{ mb: 3 }}>
            <CardElement options={{ hidePostalCode: true }} />
          </Box>
          
          <Button
            variant="contained"
            color="primary"
            sx={{
              width: '100%',
              padding: '12px 0',
              backgroundColor: '#3f51b5',
              '&:hover': {
                backgroundColor: '#303f9f',
              },
            }}
            onClick={async () => {
              if (!stripe || !elements) {
                // Stripe.js has not loaded yet
                return;
              }
          
              // Create payment method using the card details entered
              const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardElement),
              });
          
              if (error) {
                console.error('Error creating payment method:', error);
              } else {
                setPaymentDetails({ referenceStripe: paymentMethod.id });
                console.log('Payment method created successfully:', paymentMethod);
              }
            }}
          >
              Payer avec Stripe
          </Button>
        </Box>
      );
      
    case 'virement':
      return (
        <Box>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
              Instructions pour virement bancaire :
          </Typography>
          <Typography variant="body2">
            <strong>Code SWIFT:</strong> BCMAMAMC
          </Typography>
          <Typography variant="body2">
            <strong>Bénéficiaire:</strong> LUCEOM
          </Typography>
          <Typography variant="body2">
            <strong>RIB:</strong> 007 780 0003401000001686 19
          </Typography>
        
          <Typography variant="body2" sx={{ mb: 3 }}>
              Veuillez envoyer la copie du reçu par e-mail à{' '}
            <a href="mailto:contact@luceom.com">contact@luceom.com</a>.
          </Typography>
          <TextField
            label="Référence de transfert"
            placeholder="Entrez votre référence de transfert"
            fullWidth
            variant="outlined"
            onChange={(e) => setPaymentDetails({ referenceVirement: e.target.value })}
          />
        </Box>
      );
    case 'check':
      return (
        <Box>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
              Instructions pour paiement par chèque :
          </Typography>

          <Typography variant="body2">
            <strong>Libellé du chèque:</strong> Arcanes Technologies
          </Typography>
          <Typography variant="body2">
            <strong>Adresse d&lsquo;envoi:</strong>
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
              56, Farhat hachad, 3ème étage. Casablanca
          </Typography>
          <Typography variant="body2" sx={{ mb: 3 }}>
              N23, Étage 02 , Bd Zoulikha Nasri, Sidi Maarouf, 20280. Casablanca BP : 8334, CASA OASIS
          </Typography>
          <TextField
            label="Numéro du chèque"
            placeholder="Entrez votre numéro de chèque"
            fullWidth
            variant="outlined"
            onChange={(e) => setPaymentDetails({ numeroCheque: e.target.value })}
          />
        </Box>
      );
    case 'paypal':
      return (
        <Box>
          <Typography variant="subtitle1" sx={{ mb: 1 }}>
              Paiement via PayPal :
          </Typography>
          <Typography variant="body2">
              PayPal offre l&lsquo;une des plateformes de paiement les plus sécurisées au monde.
              Elle permet aux acheteurs en ligne de payer de manière simple, pratique et sécurisée.
          </Typography>
      
          {/* Champ pour saisir l'ID de transaction PayPal */}
          <TextField
            label="Identifiant de transaction PayPal"
            variant="outlined"
            fullWidth
            sx={{ mt: 2, mb: 3 }}
            onChange={(e) => setPaymentDetails({ ...paymentDetails, idTransactionPaypal: e.target.value })}
            value={paymentDetails.idTransactionPaypal || ''}
          />
      
          {/* Bouton PayPal avec redirection vers la page de paiement */}
          <Button
            className='mt-5'
            variant="contained"
            color="primary"
            onClick={() => {
              const paypalUrl = `https://www.paypal.com/cgi-bin/webscr?cmd=_xclick&business=your-paypal-email@domain.com&item_name=${encodeURIComponent(product.pack?.name)}&amount=${product.pack?.price}&currency_code=MAD`;
              window.location.href = paypalUrl;  
            }}
          >
              Payer avec PayPal
          </Button>
        </Box>
      );
    default:
      return null;
      
    }
  };


  return (

    <Drawer
      anchor="left"
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiDrawer-paper': { width: '70wv', maxWidth: '90wv' },
      }}
      ModalProps={{
        disableBackdrop: true,
      }}
    >
      <div style={{ position: 'relative', width: '100%' }}>
        <div style={{
          position: 'sticky', top: 0, left: 0, right: 0, zIndex: 1000, padding: '20px', backgroundColor: '#fff', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)', width: '100%'
        }}>
          <div className='flex w-full justify-between items-center'>
            <img src={app_logo} alt='logo' className='w-[300px]' />
            <IconButton onClick={onClose}>
              <CloseIcon sx={{ fontSize: 40 }} />
            </IconButton>
          </div>
        </div>
        <div style={{ padding: '20px', width: '50vw' }}>
          <Typography variant="h5" gutterBottom>
            Paiement
          </Typography>
          <Divider sx={{ mb: 3 }} />

          {/* Table for Product Details */}
          <TableContainer component={Paper} elevation={3} sx={{ mb: 3 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Service</TableCell>
                  <TableCell align="right">Prix</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{product.type}</TableCell>
                  <TableCell align="right">{formatPrice(product.pack?.price)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Total</strong></TableCell>
                  <TableCell align="right"><strong>{formatPrice(product.pack?.price)}</strong></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          {/* Payment Method Selection */}
          <Box mb={3}>
            <Typography variant="h6" gutterBottom>
              Méthode de paiement
            </Typography>
            <RadioGroup
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
            >
              <FormControlLabel value="stripe" control={<Radio />} label="Carte de crédit (Stripe)" />
              <FormControlLabel value="virement" control={<Radio />} label="Virement bancaire" />
              <FormControlLabel value="check" control={<Radio />} label="Chèque" />
              <FormControlLabel value="paypal" control={<Radio />} label="Paypal" />
            </RadioGroup>
            <Box mt={2}>{renderPaymentInputs()}</Box>
          </Box>

          {/* Terms and Conditions */}
          <Box mb={3}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={termsAccepted}
                  onChange={(e) => setTermsAccepted(e.target.checked)}
                />
              }
              label={
                <Typography variant="body2">
                  J&lsquo;ai lu et accepté les{' '}
                  <a href="#terms" target="_blank">
                    termes et conditions
                  </a>.
                </Typography>
              }
            />
            {showFullTerms && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                <ul>
                  <li>Les services incluent la création d’entreprise et les formalités légales.</li>
                  <li>Je suis responsable de l’exactitude des informations fournies.</li>
                  <li>Les délais dépendent des organismes administratifs.</li>
                </ul>
                <Button variant="text" onClick={() => setShowFullTerms(false)} size="small">
                  Lire moins
                </Button>
              </Typography>
            )}
            {!showFullTerms && (
              <Button variant="text" onClick={() => setShowFullTerms(true)} size="small">
                Lire plus
              </Button>
            )}
          </Box>

          {/* Action Buttons */}
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <Button onClick={onClose} variant="outlined" color="secondary">
              Annuler
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handlePayment}
            >
              Confirmer le paiement
            </Button>
          </Stack>
        </div>
      </div>
    </Drawer>
  );
};

export default PaymentDrawer;
