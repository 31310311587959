import React, { useEffect, useState } from 'react';
import CustomSelect from '../../../../../../components/inputs/customSelect';
import CustomTextInput from '../../../../../../components/inputs/customTextInput/customTextInput';
import Button from '@mui/material/Button';
import axios from 'axios';
import getEnvironement from '../../../../../../environnement';

export default function QSiegeSocial({ formData = {}, setFormData, handleNextStep, handlePreviousStep }) {
  const [domiciliationCenters, setDomiciliationCenters] = useState([]);
  const [filteredCenters, setFilteredCenters] = useState([]);
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const { villeTribunalCommerce = '', customVilleTribunal = '', siegeSocial = '', customSiegeSocial = '' } = formData;

  // Fetch domiciliation centers from API
  useEffect(() => {
    const fetchDomiciliationCenters = async () => {
      setIsLoading(true);
      try {
        const url = `${getEnvironement().API_URL}/admin/domiciliation-centers`;
        const { data } = await axios.get(url);
        setDomiciliationCenters(data);
      } catch (error) {
        console.error('Error fetching domiciliation centers:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchDomiciliationCenters();
  }, []);

  // Update filtered centers when the city is selected
  useEffect(() => {
    if (villeTribunalCommerce && villeTribunalCommerce !== 'Autre') {
      const centers = domiciliationCenters.filter(
        (center) => center.city.toLowerCase() === villeTribunalCommerce.toLowerCase()
      );
      setFilteredCenters(centers);
    } else {
      setFilteredCenters([]);
    }
  }, [villeTribunalCommerce, domiciliationCenters]);

 

  const handleInputChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleValidation = () => {
    const newErrors = {};
    if (!villeTribunalCommerce) {
      newErrors.villeTribunalCommerce = 'Sélectionnez une ville pour afficher les centres disponibles.';
    } else if (villeTribunalCommerce === 'Autre' && !customVilleTribunal) {
      newErrors.customVilleTribunal = 'Veuillez spécifier une autre ville.';
    }

    if (!siegeSocial) {
      newErrors.siegeSocial = 'Sélectionnez une adresse de centre de domiciliation.';
    } else if (siegeSocial === 'Autre' && !customSiegeSocial) {
      newErrors.customSiegeSocial = 'Veuillez spécifier une autre adresse.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleContinue = () => {
    if (handleValidation()) {
      const finalData = {
        villeTribunalCommerce: villeTribunalCommerce === 'Autre' ? customVilleTribunal : villeTribunalCommerce,
        siegeSocial: siegeSocial === 'Autre' ? customSiegeSocial : siegeSocial,
      };
      handleNextStep(finalData);

      console.log('final data', finalData);
      
    }
  };


  

  return (
    <div className="mb-10">
      <div className="bg-[#F6F7F9] rounded-lg pl-4 py-4 pr-2 mb-4">
        {/* Tribunal Select */}
        <p className="text-base font-medium text-neutral mb-2">Sélectionnez une ville pour le centre de domiciliation:</p>
        <CustomSelect
          value={villeTribunalCommerce}
          setValue={(value) => handleInputChange('villeTribunalCommerce', value)}
          defaultValue="Autre"
          items={[
            ...Array.from(new Set(domiciliationCenters.map((center) => center.city))).map((city) => ({
              label: city,
              value: city,
            })),
            { label: 'Autre', value: 'Autre' },
          ]}
          error={errors.villeTribunalCommerce}
        />
        {villeTribunalCommerce === 'Autre' && (
          <CustomTextInput
            value={customVilleTribunal}
            setValue={(value) => handleInputChange('customVilleTribunal', value)}
            placeholder="Spécifiez une autre ville"
            error={errors.customVilleTribunal}
          />
        )}

        {/* Domiciliation Center Address */}
        <p className="text-base font-medium text-neutral mb-2">Sélectionnez l&lsquo;adresse du centre de domiciliation:</p>
        {isLoading ? (
          <p>Chargement des centres de domiciliation...</p>
        ) : (
          <CustomSelect
            value={siegeSocial}
            setValue={(value) => handleInputChange('siegeSocial', value)}
            defaultValue="Autre"
            items={[
              ...filteredCenters.map((center) => ({
                label: `${center.name} (${center.address})`,
                value: center.id,
              })),
              { label: 'Autre', value: 'Autre' },
            ]}
            error={errors.siegeSocial}
          />
        )}
        {siegeSocial === 'Autre' && (
          <CustomTextInput
            value={customSiegeSocial}
            setValue={(value) => handleInputChange('customSiegeSocial', value)}
            placeholder="Spécifiez une autre adresse"
            error={errors.customSiegeSocial}
          />
        )}
      </div>

      {/* Navigation Buttons */}
      <div className="flex justify-center gap-5 mt-5">
        <Button color="info" variant="outlined" onClick={handlePreviousStep}>
          {'< Retour'}
        </Button>
        <Button color="primary" variant="contained" onClick={handleContinue}>
          Continuer
        </Button>
      </div>
    </div>
  );
}
