import { FormControl, MenuItem, Select } from '@mui/material';
import React from 'react';

export default function CustomSelect({ items, defaultValue, value, setValue, error }) {
  return (
    <div className="relative  justify-center flex flex-col w-full max-w-[300px] sm:max-w-[700px] md:max-w-[950px] mb-2 pr-2">
      <FormControl
        className="custom-select w-full"
        size="small"
      >
        <Select
          className="pl-3 pr-3 py-2 w-full outline-none border-grayv4 border-2 rounded-[8px] focus:border-black bg-white"
          defaultValue={defaultValue}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: 200, // Ensure dropdown height is manageable
                whiteSpace: 'nowrap',
                overflowX: 'auto',
              },
            },
          }}
        >
          {items.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
        {error && <p className="text-red text-sm mt-1">{error}</p>}
      </FormControl>
    </div>
  );
}
