import React, { useEffect, useState } from 'react';
import CustomTextInput from '../../../../../../components/inputs/customTextInput/customTextInput';
import PartSocial from './PartSocial';
import { range, supprimerDerniersItems } from '../../utils';
import { Button } from '@mui/material';

export default function QPartsSocial({
  formData = {},
  setFormData,
  handleNextStep,
  handlePreviousStep,
}) {
  const { partsSocial = [], nombrePartsSocial = formData.soussignes.length} = formData;
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setFormData((prev) => {
      const updatedPartsSocial = range(nombrePartsSocial).map((id) => {
        const existingData = prev.partsSocial?.find((item) => item.id === id);
        return existingData || { id, soussigne: '', partSocial: '' };
      });
  
      return {
        ...prev,
        partsSocial: supprimerDerniersItems(updatedPartsSocial, nombrePartsSocial),
      };
    });
  
    setErrors({});
  }, [nombrePartsSocial]);
  
  const modifyPartsSocialData = (idPartSocial, partSocialData) => {
    const updatedPartsSocial = [...partsSocial];
    updatedPartsSocial[idPartSocial - 1] = partSocialData; // Update the part social data
    setFormData((prev) => ({
      ...prev,
      partsSocial: updatedPartsSocial,
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!nombrePartsSocial || isNaN(nombrePartsSocial) || nombrePartsSocial < 1) {
      newErrors.nombrePartsSocial =
        'Le nombre de parts sociales est requis et doit être au moins 1.';
    }

    partsSocial.forEach((part, index) => {
      if (!part?.partSocial || part.partSocial <= 0) {
        newErrors[`partSocial_${index}`] = `Part ${index + 1}: le montant est requis et doit être supérieur à 0.`;
      }
      if (!part?.soussigne) {
        newErrors[`soussigne_${index}`] = `Part ${index + 1}: le soussigné est requis.`;
      }
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    if (validateForm()) {
      handleNextStep({ partsSocial, nombrePartsSocial });
    }
  };

  return (
    <div className="mb-10">
      <div className="bg-[#F6F7F9] rounded-lg pl-4 py-4 pr-2 mb-4">
        <p className="text-base font-medium text-neutral mb-2">
          Ajouter / Supprimer une part sociale:
        </p>
        <CustomTextInput
          type="number"
          min={1}
          value={nombrePartsSocial}
          setValue={(value) =>
            setFormData((prev) => ({
              ...prev,
              nombrePartsSocial: parseInt(value, 10) || 1,
            }))
          }
          error={errors.nombrePartsSocial}
        />
      </div>
      <div className="bg-[#F6F7F9] rounded-lg pl-4 py-4 pr-2 mb-4">
        <p className="text-base font-medium text-neutral mb-2">Parts sociales:</p>
        {partsSocial.map((partSocial) => {
          // Filter out soussignes that are already used in other parts
          const availableSoussignes = formData.soussignes.filter(
            (soussigneItem) => !partsSocial.some((part) => part.soussigne === soussigneItem)
          );

          return (
            <PartSocial
              key={partSocial.id}
              id={partSocial.id}
              partSocialData={partSocial}
              soussignes={availableSoussignes} // Use filtered soussignes
              setPartsSocial={(data) => modifyPartsSocialData(partSocial.id, data)}
              error={{
                partSocial: errors[`partSocial_${partSocial.id - 1}`],
                soussigne: errors[`soussigne_${partSocial.id - 1}`],
              }}
            />
          );
        })}
      </div>
      <div className="flex justify-center gap-5 mt-5">
        <Button color="info" variant="outlined" onClick={handlePreviousStep}>
          {'< Retour'}
        </Button>
        <Button color="primary" variant="contained" onClick={handleNext}>
          Continuer
        </Button>
      </div>
    </div>
  );
}
