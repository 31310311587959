import React, { useEffect, useState } from 'react';
import {
  Alert,  Checkbox,  FormControlLabel, TextField

} from '@mui/material';
import LoadingButton from '../../../components/inputs/loading_Button';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import HeaderContainer from '../../../components/headerContainer/headerContainer';
import './login.css';
import getEnvironement from '../../../environnement';
import { useDispatch } from 'react-redux';
import { setUser } from '../../../store/userSlice';

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [resterConnecte, setResterConnecte] = useState(false);
  const [errorLogin, setErrorLogin] = useState('');
  const [loading, setLoading] = useState(false); // Loading state added

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [status, setStatus] = useState(location.state?.data || '');

  useEffect(() => {
    setTimeout(() => setStatus(''), 4000);
  }, [status]);

  const handelLogin = () => {
    setLoading(true); // Start loading
    setErrorLogin('');

    if (!email) {
      setLoading(false);
      setErrorLogin('Veuillez entrer une adresse email.');
      setStatus('error-login');
      return;
    }
    if (!/\S+@\S+\.\S+/.test(email)) {
      setLoading(false);
      setErrorLogin('L\'adresse email n\'est pas valide.');
      setStatus('error-login');
      return;
    }

    if (!password) {
      setLoading(false);
      setErrorLogin('Veuillez entrer un mot de passe.');
      setStatus('error-login');
      return;
    }

    const url = `${getEnvironement().API_URL}/auth/login`;
    const data = { email, password };
    const config = { headers: { 'Content-Type': 'application/json' } };

    axios.post(url, data, config)
      .then((response) => {
        setLoading(false); // Stop loading on success
        if (response.status === 200) {
          dispatch(setUser(response.data.user));
          if (resterConnecte) {
            localStorage.setItem('user', JSON.stringify(response.data.user));
          }

          const referrer = localStorage.getItem('referrer');
          if (referrer) {
            navigate(referrer);
            localStorage.removeItem('referrer');
          } else {
            navigate('/dashboard');
          }
        } else if (response.status === 400) {
          setErrorLogin('Email ou mot de passe incorrect.');
          setStatus('error-login');
        }
      })
      .catch((err) => {
        setLoading(false); // Stop loading on error
        setErrorLogin(err.response?.data?.message || 'Erreur de connexion.');
        setStatus('error-login');
      });
  };

  return (
    <div className='login-container pt-32'>
      <HeaderContainer />
      <div className='login'>
        {status === 'succes-inscription' && (
          <Alert severity='success' className='alert'>
            Inscription réussie, un email vous a été envoyé pour validation.
          </Alert>
        )}
        {status === 'error-login' && errorLogin && (
          <Alert severity="error" className='alert'>{errorLogin}</Alert>
        )}
        <div className='login-form'>
          <p className='title'>Connexion:</p>
          <label htmlFor='email' className='email'>
            Email:
            <TextField
              id="email"
              variant="outlined"
              type='email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </label>
          <label htmlFor='password' className='password'>
            Mot de passe:
            <TextField
              id="password"
              variant="outlined"
              type='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </label>
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={resterConnecte}
                onChange={(e) => setResterConnecte(e.target.checked)}
              />
            }
            label="Rester connecté"
            labelPlacement="end"
            className='resterConnecte'
          />
          <LoadingButton
            className='login-button'
            variant='contained'
            onClick={handelLogin}
            loading={loading} // Pass loading state
          >
            Se connecter
          </LoadingButton>
          <div className='login-link-container'>
            <Link to={'/forgot-password'}>Mot de passe oublié ?</Link>
            <Link to={'/inscription'}>Créer un nouveau compte</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
