import React, { useState } from 'react';
import { IconButton, Button, TextField, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSpring } from '@react-spring/web';
import { format } from 'date-fns';
import logo from '../../../assets/images/LOGO-LS@2x.png';
import documentIcon from '../../../assets/icons/documentIcon.png';
import productIdIcon from '../../../assets/icons/productIdIcon.png';
import axios from 'axios';
import DocumentsBox from './documentsBox';
import DocumentsTable from './documentsTable';
import getEnvironement from '../../../environnement';

import { Bounce, toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';


export default function DetailsProduct({ setOpenDrawer, product }) {
  const [legalizedFile, setLegalizedFile] = useState(null);

  console.log('id id ',product.product_id);

  const handleFileChange = (e) => {
    setLegalizedFile(e.target.files[0]);
  };

  const handleUpload = async (documentId) => {

    if (!legalizedFile) {
      toast.error('Veuillez sélectionner un fichier d\'abord.');
      return;
    }

    const formData = new FormData();
    formData.append('pdf', legalizedFile);
    formData.append('fileName', legalizedFile.name);
    formData.append('fileType', 'légalisé');
    formData.append('product_id', product?.product_id); 
    formData.append('productId', product?.id);         
    formData.append('document_id', documentId);

    console.log('Document ID:', documentId);
    console.log('FormData Content:', [...formData.entries()]);

    try {
      const response = await axios.post(
        `${getEnvironement().API_URL}/admin/products/documents/legalized`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      toast.success(response.data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Bounce,
      });
    } catch (error) {
      console.error('Upload Error:', error.response?.data);
      toast.error(
        error.response?.data?.message || 'Erreur lors du téléchargement du fichier.', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
        });
    }
  };

  return (
    <div className='w-full'>
      <ToastContainer />

      <div className='flex p-5 justify-between items-center shadow-md'>
        <img src={logo} alt='logo' className='w-[300px]' />
        <IconButton onClick={() => setOpenDrawer(false)}>
          <CloseIcon sx={{ fontSize: 40 }} />
        </IconButton>
      </div>
      <div className='p-10'>
        <p className='font-poppins font-medium text-2xl mb-5'>Documents reçus :</p>
        <DocumentsTable
          header={['Id_document', 'Nom', 'Date', 'Document']}
          data={product.documents.filter((doc) => doc.type === 'reçu')}
          isProductPayed={true}
        />
      </div>
      <div className='px-10'>
        <p className='font-poppins font-medium text-2xl mb-5'>Documents à légaliser :</p>
        <DocumentsTable
          header={['Id_document', 'Nom', 'Date', 'Document']}
          data={product.documents.filter((doc) => doc.type === 'légalisé')}
          isProductPayed={true}
          actions={(document) => (
            <div>
              <TextField
                type='file'
                onChange={handleFileChange}
                fullWidth
                variant='outlined'
              />
              <Button
                variant='contained'
                color='primary'
                onClick={() => handleUpload(document.id)}
              >
                Upload Légalized Document
              </Button>
            </div>
          )}
        />
      </div>

      <div className='p-10'>
        <p className='font-poppins font-medium text-2xl mb-5'>Documents Légalisé :</p>
        <DocumentsTable
          header={['Id_document', 'Nom', 'Date', 'Document']}
          data={product.documents.filter((doc) => doc.type === 'légalisé')}
          isProductPayed={true}
        />
      </div>
    </div>
  );
}

function ProductsTable({ header, data }) {
  const [open, setOpen] = useState(false);
  const [activeProduct, setActiveProduct] = useState('');
  const [filePathes, setFilePathes] = useState([]);

  const [springs, api] = useSpring(() => ({
    from: { transform: 'translateY(100%)' },
  }));

  const handelClickDocuments = (productId) => {
    setActiveProduct(productId);
    api.start({
      from: { transform: 'translateY(100%)' },
      to: { transform: 'translateY(0)' },
      config: { tension: 300, friction: 20 },
    });
    const product = data.find((prod) => prod.product_id === productId);
    setFilePathes(product?.documents?.map((doc) => doc.file_name) || []);
    setOpen(true);
  };

  return (
    <>
      <DocumentsBox
        open={open}
        setOpen={setOpen}
        springs={springs}
        productId={activeProduct}
        filePathes={filePathes}
      />
      <table className='table-auto w-full'>
        <thead>
          <tr className='border-solid border-b border-[#EBEBEB]'>
            {header.map((item, index) => (
              <th
                key={item}
                className={`font-poppins font-medium text-sm/[16px] opacity-50 py-5 ${index === 0 && 'text-start w-24'}`}
              >
                {item}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index} className='border-solid border-b border-[#EBEBEB]'>
              <td className='flex py-2 items-center font-poppins font-normal text-sm/[16px]'>
                <img src={productIdIcon} alt='Product ID' />
                <p className='ml-3 break-words'>{item.product_id}</p>
              </td>
              <td className='font-poppins font-normal text-sm/[16px] text-center opacity-50'>
                {item.type}
              </td>
              <td className='font-poppins font-normal text-sm/[16px] text-center'>
                {format(new Date(item.created_at), 'yyyy-MM-dd HH:mm')}
              </td>
              <td className='py-5 text-center'>
                <Tooltip title='Documents'>
                  <IconButton onClick={() => handelClickDocuments(item.product_id)}>
                    <img src={documentIcon} alt='Document Icon' />
                  </IconButton>
                </Tooltip>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}

export { ProductsTable };
