import React, { useState } from 'react';
import { IconButton, Button, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import logo from '../../../assets/images/LOGO-LS@2x.png';
import DocumentsTable from './documentsTable';
import axios from 'axios';
import UploadIcon from '@mui/icons-material/Upload';
import getEnvironement from '../../../environnement';
import { Bounce, toast , ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

export default function DetailsProduct({ setOpenDrawer, product  , fetchProducts}) {
  const [legalizedFile, setLegalizedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false); 

  const handleFileChange = (e) => {
    setLegalizedFile(e.target.files[0]);
  };

  const handleUpload = async (documentId) => {
    if (!legalizedFile) {
      toast.error('Veuillez sélectionner un fichier d\'abord.', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        transition: Bounce,
      });
      return;
    }
  
    const formData = new FormData();
    formData.append('pdf', legalizedFile);
    formData.append('fileName', legalizedFile.name);
    formData.append('fileType', 'légalisé');
    formData.append('product_id', product?.product_id);
    formData.append('productId', product?.id);
    formData.append('document_id', documentId);
  
    const url = `${getEnvironement().API_URL}/admin/products/documents/legalized`;
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
  
    setIsUploading(true);
  
    await axios
      .post(url, formData, config)
      .then((response) => {
        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
        });
        setTimeout(() => {
          fetchProducts();
        }, 5000);
      })
      .catch((error) => {
        console.error('Upload Error:', error.response?.data);
        toast.error(
          error.response?.data?.message || 'Erreur lors du téléchargement du fichier.',
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
            transition: Bounce,
          }
        );
      })
      .finally(() => {
        setIsUploading(false);
      });
  };
  

  return (
    <div className='w-full'>
      <ToastContainer />
      <div className='flex p-5 justify-between items-center shadow-md'>
        <img src={logo} alt='logo' className='w-[300px]' />
        <IconButton onClick={() => setOpenDrawer(false)}>
          <CloseIcon sx={{ fontSize: 40 }} />
        </IconButton>
      </div>
      <div className='p-10'>
        <p className='font-poppins font-medium text-2xl mb-5'>Documents reçus :</p>
        <DocumentsTable
          header={['Id_document', 'Nom', 'Date', 'Document']}
          data={product.documents.filter((document) => document.type === 'reçu')}
          isProductPayed={product?.status?.status === 'Payé'}
        />
      </div>
      <div className='px-10'>
        <p className='font-poppins font-medium text-2xl mb-5'>Documents à légaliser :</p>

        {/* Instructions Section */}
        <div className='mb-5'>
          <Typography variant='h6' className='font-poppins font-medium'>
            Instructions pour légaliser un document :
          </Typography>
          <Typography className='font-poppins font-normal text-sm'>
            1. Téléchargez le document en cliquant sur l&lsquo;icône de téléchargement.
          </Typography>
          <Typography className='font-poppins font-normal text-sm'>
            2. Légalisez le document auprès des autorités compétentes.
          </Typography>
          <Typography className='font-poppins font-normal text-sm'>
            3. Scannez le document légalisé et chargez-le ci-dessous.
          </Typography>
        </div>

        {/* Document Table with Upload Actions */}
        <DocumentsTable
          header={['Id_document', 'Nom', 'Date', 'Document']}
          data={product.documents.filter((document) => document.type === 'légaliser')}
          isProductPayed={product?.status?.status === 'Payé'}
          actions={(document) => {
            // Hide upload button if the document has already been legalized
            const isLegalized = document.legalized ;
            return !isLegalized && (
              <div className='flex items-center justify-center space-x-4'>
                {/* Upload Button with File Input */}
                <Button
                  variant='outlined'
                  startIcon={<UploadIcon />}
                  style={{
                    height: '40px',
                    padding: '0 16px',
                    fontSize: '14px',
                    color: '#374151', // Hex color for text
                    borderColor: '#D1D5DB', // Hex color for border
                    position: 'relative', // For absolute file input positioning
                  }}
                >
                  {legalizedFile ? legalizedFile.name : 'Sélectionner un fichier'}

                  {/* Hidden File Input */}
                  <input
                    type='file'
                    onChange={handleFileChange}
                    style={{
                      position: 'absolute',
                      left: 0,
                      top: 0,
                      cursor: 'pointer',
                      opacity: 0,
                      width: '100%',
                      height: '100%',
                    }}
                  />
                </Button>

                {/* Upload Button for Submitting the File */}
                <Button
                  variant='contained'
                  color='primary'
                  onClick={() => handleUpload(document.id)}
                  disabled={isUploading || !legalizedFile}
                  sx={{
                    padding: '8px 20px',
                    fontSize: '14px',
                    borderRadius: '6px',
                    backgroundColor: isUploading || !legalizedFile ? '#888' : '#1976D2', // Hex color for primary background
                    '&:hover': {
                      backgroundColor: isUploading || !legalizedFile ? '#888' : '#1565C0', // Hex color for hover effect
                    },
                  }}
                >
                  {isUploading ? 'Téléchargement en cours...' : 'Télécharger'}
                </Button>

              
            

              </div>
            );
          }}
        />
      </div>
      <div className='p-10'>
        <p className='font-poppins font-medium text-2xl mb-5'>Documents Légalisé :</p>
        <DocumentsTable
          header={['Id_document', 'Nom', 'Date', 'Document']}
          data={product.documents.filter((document) => document.type === 'légalisé')}
          isProductPayed={product?.status?.status === 'Payé'}
        />
      </div>
    </div>
  );
}
