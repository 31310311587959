import React from 'react';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import './contact.css';
import { Button, TextField } from '@mui/material';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';

export default function Contact() {
  return (
    <div className='contactContainer pt-32'>
      <Header active='Contact' />
      <div className='contact '>
        <div className='contactInfo '>
          <h1>Prenez contact avec nous.</h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, 
            luctus nec ullamcorper mattis, pulvinar dapibus leo.
          </p>
          <div className='hr' />
          <h3>Retrouvez-nous sur</h3>
          <p className='infoText'><LocationOnOutlinedIcon className='icon'/> Casablanca, Maroc</p>
          <h3>Rejoignez-nous à</h3>
          <p className='infoText'><EmailOutlinedIcon className='icon' /> info@LegalStation.com</p>
          <p className='infoText'><CallOutlinedIcon className='icon' /> +212 631 908 960</p>
        </div>
        <div className='contactForm'>
          <div className='form'>
            <h3>Contactez-nous</h3>
            <div className='name'>
              <TextField className='inputPrenom' id="prenom" label="Prénom" variant="outlined" fullWidth/>
              <TextField id="nom" label="Nom" variant="outlined" fullWidth />
            </div>
            <div>
              <TextField id="email" label="Adresse email" variant="outlined" type='email' fullWidth />
            </div>
            <div>
              <TextField
                id="message"
                label="Message"
                variant="outlined"
                rows={4}
                multiline
                fullWidth
              />
            </div>
            <Button variant="contained">Envoyer</Button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
