import React from 'react';
import { Button } from '@mui/material';
import contactUsImage from '../../../../assets/images/contactUs-image.svg';
import './contactUs.css';

export default function ContactUs() {
  const cards = [
    {
      title: 'Domiciliation',
      description: 'Afin d’achever sa mission à 100% et pour vous accompagner à la réussite de lancement de votre projet, Creaste Maroc propose un accompagnement Digital allant de Création de Site Web, Publicité Digitale, le Emailing et le Marketing Digital',
    },
    {
      title: 'Comptabilité',
      description: 'Afin d’achever sa mission à 100% et pour vous accompagner à la réussite de lancement de votre projet, Creaste Maroc propose un accompagnement Digital allant de Création de Site Web, Publicité Digitale, le Emailing et le Marketing Digital',
    },
  ];

  return (
    <section className="contactUsContainer">
      <div className="contactUsText py-[76px] text-center">
        <p className="title t1">Meilleure expertise, meilleurs tarifs.</p>
        <p className="title t2 w-[1020px]">
          Nous avons accès aux meilleurs experts au Maroc, offrant des tarifs compétitifs
        </p>
        <p className="title t3">
          Profitez de conseils personnalisés et d&apos;un accompagnement adapté à vos besoins, pour garantir la réussite de votre projet.
        </p>
        <Button className="button" variant="contained" size="large">
          Découvrez nos tarifs
        </Button>
      </div>
      <div className="contactUs flex gap-16">
        <div className="content-side">
          <h1>Un accompagnement complet pour le succès de votre projet.</h1>
          <p>Pour une gestion optimale de votre entreprise dès le départ.</p>

          {cards.map((card, index) => (
            <div key={index} className="contactUs-card">
              <h3 className="contactUs-card-title">{card.title}</h3>
              <p className="contactUs-card-description">{card.description}</p>
            </div>
          ))}
        </div>
        <div className="image-side">
          <img src={contactUsImage} alt="Domiciliation" />
        </div>
      </div>
    </section>
  );
}
