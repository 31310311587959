import React from 'react';
import { ReactComponent as VIcon } from '../../assets/icons/vIcon.svg';
import { Button } from '@mui/material';

export default function PackItem({
  offer,
  price,
  subPrice,
  image,
  items,
  onClick,
}) {
  // Helper function to format price as currency
  const formatPrice = (value) => {
    return new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'MAD',
    })
      .format(value)
      .replace('MAD', 'DH'); // Replace currency symbol with DH
  };

  return (
    <div className='rounded-[25px] border border-[#DDDDDD] flex flex-col items-center w-full lg:w-1/3'>
      <div className='w-full border-b border-[#DDDDDD] pt-5'>
        <p
          className={`text-center font-large font-inter  ${offer ? 'text-2xl' : 'text-4xl'}`}
        >
          {offer ? (
            <>
              <span className="bg-[#FFD700] text-black px-2 py-1 rounded-lg">
                {offer}
              </span>{' '}
              {formatPrice(price)}
            </>
          ) : (
            `${formatPrice(price)}`
          )}
        </p>
        <p
          className='text-center font-medium font-inter text-sm line-through h-5'
          style={{ textDecorationColor: 'red' }}
        >
          {subPrice && formatPrice(subPrice)}
        </p>
      </div>
      <div className='my-10'>
        <img src={image} alt='pack' />
      </div>
      <div className='flex flex-col items-center'>
        {items.map((item, index) => {
          return (
            <div key={index} className='flex items-center my-3 w-5/6'>
              <VIcon className='flex-none' />
              <p className='ml-3 font-inter'>{item}</p>
            </div>
          );
        })}
      </div>
      <div className='my-5'>
        <Button variant='contained' onClick={onClick}>
          Choisir ce plan
        </Button>
      </div>
    </div>
  );
}
