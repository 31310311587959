import React from 'react';
import './footer.css';
import logoLegalStation from '../../assets/images/LOGO-LS@2x.png';

export default function Footer() {
  return (
    <div>
      <div className="footerContainer">
        <div className="footer">
          <div className="footer-logo">
            <img src={logoLegalStation} alt="logo-legal-station" />
            <p>Création d&lsquo;entreprise au Maroc<br />Simple, Rapide, 100% Digital</p>
          </div>
          <div>
            <h4>Nos Services</h4>
            <p>Création d&lsquo;entreprise au Maroc</p>
            <p>Comptabilité en ligne</p>
            <p>Conseil et documents juridiques</p>
            <p>Conseil en gestion d&lsquo;entreprise</p>
            <p>Offre exceptionnelle</p>
          </div>
          <div>
            <h4>À propos</h4>
            <p>Équipe</p>
            <p>Recrutement</p>
            <p>Avis clients</p>
            <p>Privacy Policy</p>
          </div>
          <div>
            <h4>Partout au Maroc</h4>
            <p>Casablanca</p>
            <p>Rabat</p>
            <p>Marrakech</p>
            <p>Tanger</p>
            <p>Fès</p>
            <p>Agadir</p>
            <p>Laayoune</p>
            <p>Dakhla</p>
          </div>
        </div>
      </div>
      <div className="copywriteContainer">
        <div className="copywrite">
          <div className="flex gap-20">
            <p>© 2024 Copyright By LegalStation</p>
            <p>Conditions Générales</p>
            <p>Politique de Remboursement</p>
            <p>Politique de Confidentialité</p>
            <p>Politique de Cookies</p>
          </div>
          <p>
            LegalStation.ma n&lsquo;est pas un cabinet d&lsquo;avocats ni un cabinet d&lsquo;expertise comptable. Conformément à la réglementation en vigueur, LegalStation.ma fournit uniquement de l&lsquo;information juridique, un logiciel permettant de générer des documents juridiques à partir de modèles, un service de formalités d&lsquo;entreprises, ainsi qu&lsquo;un service de mise en relation avec des professionnels spécialisés dans les services aux entreprises. Il est recommandé de faire appel à un avocat ou à un expert-comptable pour valider vos documents avant leur utilisation et pour toute question juridique ou comptable. Consultez nos conditions générales.
          </p>
        </div>
      </div>
    </div>
  );
}
