import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './headerTitles.css';

export default function HeaderTitles() {
  const navigate = useNavigate();

  const navigateToDashboard = () => {
    navigate('/dashboard');
  };

  return (
    <section className='headerTitlesContainer py-16 '>
      <div className='headerTitles'>
        <p className='title t1'>
          Création d&apos;entreprise , Domiciliation et Comptabilité, Conseils et documents juridiques
        </p>
        <p className='title t2'>
          Création d&apos;entreprise au Maroc  Simple, Rapide, 100% Digital
        </p>
        <p className='title t3'>
          Créez et gérez votre entreprise en ligne avec l’aide de nos experts en comptabilité, conseil juridique, et gestion d’entreprise.
        </p>
        <Button className='button' variant="contained" size='large' onClick={navigateToDashboard}>Click here</Button>
      </div>
    </section>
  );
}